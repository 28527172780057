import React, { ReactNode } from 'react';
import { IconSortAsc, IconSortDesc } from '@components/icons';
import { TableSortLabel } from '@mui/material';
import { SortOrder } from '@declarations/index';

type Props = {
  id: string | number;
  order: SortOrder;
  label: string | ReactNode;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
};
export const Sorting = ({
  id,
  order,
  orderBy,
  label,
  onRequestSort,
}: Props) => {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableSortLabel
      active
      direction={orderBy === id ? order : 'asc'}
      onClick={createSortHandler(id as string)}
      IconComponent={
        orderBy === id && order === 'asc' ? IconSortAsc : IconSortDesc
      }
    >
      {label}
    </TableSortLabel>
  );
};
