import { styled, css } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export const StyledLink = styled(Link)<{ small?: string }>(
  ({ theme, small }) => css`
    text-decoration: none;
    font-size: ${small
      ? theme.typography.fontSizeSmall
      : theme.typography.fontSizeBase};
    color: ${theme.palette.primary.dark};
  `
);

export const StyledButtonLink = styled(Button)<{ primary?: string }>(
  ({ theme, primary }) => css`
    padding: 0;
    color: ${primary ? theme.palette.primary.dark : theme.palette.grey[600]};

    &:hover {
      background-color: transparent;
    }
  `
);
