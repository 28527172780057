import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import FormChunk from '@components/form-chunk/form-chunk';
import { Box, Stack } from '@mui/material';
import { FormFieldView } from '@components/form-field-view/form-field-view';
import {
  AddressDTO,
  EducationOrganization,
} from '@declarations/education-organization';
import { replaceInvalidValue } from '@utils/index';
import Push from '@components/ui/push';

export const AddressesView: FC = () => {
  const { values } = useFormikContext<EducationOrganization>();

  const { organizationAddresses: data } = values;

  const title = 'Адрес';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {data?.length
        ? data?.map((el: AddressDTO, index: number) => (
            <FormChunk expandable title={title} index={index + 1} key={el.id}>
              <Stack direction="row">
                <Box sx={{ width: 185 }}>
                  <FormFieldView
                    title="Тип адреса"
                    value={replaceInvalidValue(el.type)}
                  />
                </Box>
                <Box sx={{ width: 185 }}>
                  <FormFieldView
                    title="Почтовый индекс"
                    value={replaceInvalidValue(el.postalCode)}
                  />
                </Box>
                <FormFieldView
                  title="Страна"
                  value={replaceInvalidValue(el.country)}
                />
              </Stack>

              <Push size={16} />

              <FormFieldView
                title="Полный текст адреса"
                value={replaceInvalidValue(el.fullAddress)}
              />

              <Push size={16} />

              <Stack direction="row">
                <Box sx={{ width: 286 }}>
                  <FormFieldView
                    title="Город или населённый пункт"
                    value={replaceInvalidValue(el.city)}
                  />
                </Box>

                <FormFieldView
                  title="Район"
                  value={replaceInvalidValue(el.district)}
                />
              </Stack>

              <Push size={16} />

              <FormFieldView
                title="Административный субъект страны (регион)"
                value={replaceInvalidValue(el.fullAddress)}
              />

              <Push size={16} />

              <Stack direction="row">
                <Box sx={{ width: 185 }}>
                  <FormFieldView
                    title="Номер кв./офиса"
                    value={replaceInvalidValue(el.flat)}
                  />
                </Box>

                <Box sx={{ width: 185 }}>
                  <FormFieldView
                    title="Корпус/строение"
                    value={replaceInvalidValue(el.building)}
                  />
                </Box>

                <FormFieldView
                  title="Номер дома"
                  value={replaceInvalidValue(el.house)}
                />
              </Stack>

              <Push size={16} />

              <FormFieldView
                title="Улица"
                value={replaceInvalidValue(el.street)}
              />
            </FormChunk>
          ))
        : 'Адреса отсутствуют'}
    </Box>
  );
};
