import React, { FC, useMemo } from 'react';
import { EducationOrganizationList } from '@declarations/education-organization';
import {
  StyledIconButton,
  WrapTablePopover,
} from '@pages/educational-organizations/list/styles';
import { IconPencil, IconTrash, IconVerticalDots } from '@components/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants/routes';
import { TableColumnType } from '@components/ui/table/types';
import DropDown from '@components/ui/drop-down';
import { ActiveIcon } from '@components/ui/chip/active-icon';
import { Table } from '@components/ui/table';
import { StyledChip } from '@components/ui/chip';

type Props = {
  data: EducationOrganizationList[];
};

export const TableOrganizations: FC<Props> = ({ data }) => {
  const navigate = useNavigate();

  const columns: TableColumnType[] = useMemo(
    () => [
      {
        id: 'name',
        label: 'Полное наименование',
        width: '20%',
        render: (item) => item.name,
      },
      {
        id: 'alias',
        label: 'Краткое наименование',
        width: '20%',
        render: (item) => item.alias,
      },
      {
        id: 'type',
        label: 'Тип организации',
        render: (item) => {
          return item.type;
        },
      },
      {
        id: 'active',
        label: 'Признак активности',
        width: '180px',
        render: (item) => (
          <StyledChip
            icon={<ActiveIcon isActive={item.active} />}
            label={item.active ? 'Активна' : 'Не активна'}
          />
        ),
      },
      {
        id: 'partOf',
        label: 'Головная организация (у филиала)',
        render: (item) => {
          return (
            <WrapTablePopover editColumn isActive={!!item.partOf}>
              {item.partOf}
              <DropDown
                id={item.id}
                control={
                  <StyledIconButton>
                    <IconVerticalDots />
                  </StyledIconButton>
                }
                items={[
                  {
                    icon: <IconPencil />,
                    label: 'Редактировать',
                    onClick: () =>
                      navigate(`${ROUTES.eduOrganizations}/view/${item.id}`),
                  },
                  {
                    icon: <IconTrash />,
                    label: 'Удалить',
                    onClick: () => {},
                  },
                ]}
              />
            </WrapTablePopover>
          );
        },
      },
    ],
    [navigate]
  );

  return <Table data={data} columns={columns} />;
};
