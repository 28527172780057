import { css, styled } from '@mui/material/styles';
import { TextareaAutosize } from '@mui/material';

export const StyledTextAreaAutoSize = styled(TextareaAutosize)(
  ({ theme }) => css`
    padding: 10px 12px;
    width: 100% !important;
    background-color: white;
    border-radius: 12px;
    border: none;
    font-size: 16px;
    font-family: inherit;
    &::placeholder {
      color: #93979e;
    }

    &:focus-visible {
      border: 1px solid ${theme.palette.text.primary};
      outline: none;
    }
  `
);
