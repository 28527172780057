import { Routes, Route } from 'react-router-dom';
import MainPage from '@pages/main-page';
import ProfilesPage from '@pages/profiles-page/list';
import { EduOrganizationList } from '@pages/educational-organizations/list';

import AuthLayout from '@pages/auth-layout';
import { EducationalOrganization } from '@pages/educational-organizations/organization';
import ProtectedRoute from '@components/protected-route';
import { ProfilePage } from '@pages/profile-page/profile-page';
import { ROUTES as RouteList } from './constants/routes';

export default function App() {
  return (
    <Routes>
      <Route
        path={RouteList.main}
        element={
          <ProtectedRoute>
            <MainPage />
          </ProtectedRoute>
        }
      >
        <Route path={RouteList.allProfiles} element={<ProfilesPage />} />
        <Route path={RouteList.profileItemEdit} element={<ProfilePage />} />
        <Route path={RouteList.profileCreate} element={<ProfilePage />} />
        <Route
          path={RouteList.eduOrganizations}
          element={<EduOrganizationList />}
        />

        <Route
          path={RouteList.createEduOrganization}
          element={<EducationalOrganization />}
        />

        <Route
          path={RouteList.eduOrganization}
          element={<EducationalOrganization />}
        />
      </Route>
      <Route path={RouteList.auth} element={<AuthLayout />} />
    </Routes>
  );
}
