import { css, styled } from '@mui/material/styles';
import {
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from '@mui/material';

export const StyledIndentRow = styled(TableRow)(
  () => css`
    height: 12px;
  `
);

export const StyledTableRow = styled(TableRow)(
  () => css`
    background-color: transparent !important;

    &:hover {
      .MuiTableCell-root {
        background-color: #f0f2fc;
      }

      .MuiChip-root {
        background-color: #fff;
      }
    }

    .MuiTableCell-root {
      &:first-of-type {
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
      }

      &:last-of-type {
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
      }
    }
  `
);

export const StyledTableHead = styled(TableHead)(
  ({ theme }) => css`
    .${tableCellClasses.head} {
      background: ${theme.palette.grey[300]};
      font-weight: normal;

      &:first-of-type {
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
      }

      &:last-of-type {
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
      }
    }
  `
);

export const StyledTableCell = styled(TableCell)<{
  width?: string;
  center?: string;
}>(
  ({ width, center }) => css`
    width: ${width};
    padding-top: 12px;
    padding-right: 8px;
    padding-bottom: 12px;
    border: none;
    line-height: normal;
    text-align: ${center ? 'center' : 'normal'};

    &:first-of-type {
      padding-left: 12px;
    }

    &:last-of-type {
      padding-right: 12px;
    }

    svg {
      display: block;
    }
  `
);
