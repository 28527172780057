import { Collapse, Typography } from '@mui/material';
import { ExpandableTitle } from '@components/form-expandable-title/form-expandable-title';
import React, { FC, useState } from 'react';
import { FormBlockWrapper } from './styles';

type FormBlockType = {
  title: string;
  children: React.ReactNode;
  addButton?: React.ReactNode;
  expandable?: boolean;
  isExpanded?: boolean;
};

export const FormBlock: FC<FormBlockType> = ({
  title,
  children,
  addButton,
  expandable = false,
  isExpanded = false,
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <FormBlockWrapper>
      <div>
        {expandable ? (
          <ExpandableTitle
            title={title}
            expanded={expanded}
            onClick={handleExpanded}
            typographyProps={{ variant: 'h2' }}
          />
        ) : (
          <Typography variant="h2">{title}</Typography>
        )}
      </div>
      <div>
        {expandable ? (
          <Collapse in={expanded} timeout={0}>
            {children}
          </Collapse>
        ) : (
          children
        )}
      </div>
      <div>{addButton}</div>
    </FormBlockWrapper>
  );
};
