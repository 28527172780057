import { Backdrop, Fade, Modal } from '@mui/material';
import React, { ReactNode } from 'react';
import { Close, InnerWrapModal } from '@components/ui/modal/styles';
import { IconClose } from '@components/icons';

export type TModal = {
  open: boolean;
  handleClose: () => void;
  size?: 'small' | 'medium' | 'large';
  children: ReactNode;
};

export const StyleModal: React.FC<TModal> = ({
  open,
  handleClose,
  size = 'small',
  children,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(12, 21, 36, 0.9)',
        },
      }}
    >
      <Fade in={open}>
        <InnerWrapModal size={size}>
          <Close onClick={handleClose}>
            <IconClose color="rgba(147, 151, 158, 1)" width={9} height={9} />
          </Close>
          {children}
        </InnerWrapModal>
      </Fade>
    </Modal>
  );
};
