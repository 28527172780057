import React, { FC } from 'react';
import { navigationLinks } from '@mock-data/navigation-list';
import { StyledDrawer, StyledHead } from '@components/navigation-bar/styles';
import logo from '@assets/images/logo.svg';
import { IconChevronLeft } from '@components/icons';
import { useGetAccountList } from '@queries/user';
import { ROUTES } from '@constants/routes';
import NavButton from './nav-button';

export const openedDrawerWidth = 260;
export const closedDrawerWidth = 80;

type Props = {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
};

const NavigationBar: FC<Props> = ({ openDrawer, setOpenDrawer }) => {
  const { data } = useGetAccountList();

  return (
    <StyledDrawer
      variant="permanent"
      anchor="left"
      opendrawer={Number(openDrawer)}
    >
      <StyledHead opendrawer={Number(openDrawer)}>
        <img src={logo} alt="Logo" />
        <button type="button" onClick={() => setOpenDrawer(!openDrawer)}>
          <IconChevronLeft />
        </button>
      </StyledHead>

      {navigationLinks.map(({ link, name, icon }) => (
        <NavButton
          key={name}
          to={link}
          icon={icon}
          openDrawer={openDrawer}
          {...(link === ROUTES.allProfiles &&
            data && { counter: data.content.length })}
        >
          {name}
        </NavButton>
      ))}
    </StyledDrawer>
  );
};

export default NavigationBar;
