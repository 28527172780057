import { useParams } from 'react-router-dom';
import ProfilePageEdit from './profile-page-edit';
import { ProfilePageDataLoader } from './profile-page-data-loader';

export const ProfilePage = () => {
  const params = useParams<{ userId: string }>();
  const userId = parseInt(params.userId as string, 10);
  return userId ? (
    <ProfilePageDataLoader
      userId={userId}
      renderWithData={({ user, status }) => (
        <ProfilePageEdit user={user} status={status} />
      )}
    />
  ) : (
    <ProfilePageEdit />
  );
};
