import React from 'react';
import { FormFieldView } from '@components/form-field-view/form-field-view';
import FormChunk from '@components/form-chunk/form-chunk';
import { Box, Stack } from '@mui/material';
import { FormBlock } from '@components/form-block/form-block';
import { useFormikContext } from 'formik';
import { EducationOrganization } from '@declarations/education-organization';
import Push from '@components/ui/push';
import { replaceInvalidValue } from '@utils/index';
import { formatDate } from '@utils/date';
import { ActiveIcon } from '@components/ui/chip/active-icon';

export const GeneralView = () => {
  const { values } = useFormikContext<EducationOrganization>();
  return (
    <FormBlock title="Общие сведения">
      <FormChunk>
        <Box>
          <FormFieldView title="ID" value={replaceInvalidValue(values.id)} />

          <Push size={16} />

          <FormFieldView
            title="Полное наименование"
            value={replaceInvalidValue(values.name)}
          />

          <Push size={16} />

          <FormFieldView
            title="Краткое наименование"
            value={replaceInvalidValue(values.alias)}
          />

          <Push size={16} />

          <FormFieldView
            title="Тип организации"
            value={replaceInvalidValue(values.type)}
          />

          <Push size={16} />

          <Stack direction="row">
            <Box sx={{ width: 286 }}>
              <FormFieldView
                title="ФИО руководителя"
                value={replaceInvalidValue(values.executiveName)}
              />
            </Box>

            <FormFieldView
              title="Должность руководителя"
              value={replaceInvalidValue(values.executivePosition)}
            />
          </Stack>

          <Push size={16} />

          <FormFieldView
            title="Головная организация (у филиала)"
            value={replaceInvalidValue(values.partOf?.name)}
          />

          <Push size={16} />

          <Stack direction="row">
            <Box sx={{ width: 286 }}>
              <FormFieldView
                title="Дата актуальности сведений"
                value={
                  values.effectiveDate ? formatDate(values.effectiveDate) : '-'
                }
              />
            </Box>

            <FormFieldView
              title="Признак активности"
              value={
                <Stack direction="row" alignItems="center">
                  <ActiveIcon isActive={values.active} />
                  <Push horizontal size={8} />
                  {values.active ? 'Активна' : 'Не активна'}
                </Stack>
              }
            />
          </Stack>
        </Box>
      </FormChunk>
    </FormBlock>
  );
};
