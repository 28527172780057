import React, { FC } from 'react';
import { IconPlus, IconTrash } from '@components/icons';
import { EditWrapper } from '@pages/profile-page/forms/styles';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  getIn,
  useFormikContext,
} from 'formik';
import { FormSelect } from '@components/form-select/form-select';
import FormChunk from '@components/form-chunk/form-chunk';
import { FormBlock } from '@components/form-block/form-block';
import {
  AddressDTO,
  EducationOrganization,
} from '@declarations/education-organization';
import { Button, IconButton, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { TextAreaAutoSize } from '@components/ui/textarea-auto-size';

type Props = {
  name: string;
};

const initialValue: AddressDTO = {
  id: 1,
  type: 'POSTAL',
  country: '',
  region: '',
  city: '',
  district: '',
  street: '',
  house: null,
  building: '',
  flat: '',
  postalCode: null,
  fullAddress: '',
  organizationId: 0,
};

const typesAddresses = [
  {
    label: 'Почтовый адрес',
    value: 'POSTAL',
  },
];

const countries = [
  {
    label: 'Россия',
    value: 1,
  },
];

export const AddressesEdit: FC<Props> = ({ name }) => {
  const { touched, errors } = useFormikContext<EducationOrganization>();
  return (
    <FieldArray name={name}>
      {({ form, push, remove }: FieldProps & FieldArrayRenderProps) => {
        const addresses: AddressDTO[] = form.getFieldProps(name).value;
        return (
          <FormBlock expandable title="Адреса организации">
            <EditWrapper>
              {addresses?.length > 0
                ? addresses?.map((item, index: number) => {
                    const type = `${name}[${index}].type`;
                    const touchedType = getIn(touched, type);
                    const errorType = getIn(errors, type);

                    const country = `${name}[${index}].country`;
                    const touchedCountry = getIn(touched, country);
                    const errorCountry = getIn(errors, country);
                    return (
                      <FormChunk
                        expandable
                        title="Адрес"
                        index={index + 1}
                        key={item.id}
                        isEdit
                        deleteButton={
                          <IconButton onClick={() => remove(index)}>
                            <IconTrash />
                          </IconButton>
                        }
                      >
                        <EditWrapper>
                          <FormFieldEdit required label="Тип адреса">
                            <Field
                              name={`${name}.${index}.type`}
                              as={FormSelect}
                              options={typesAddresses}
                              helperText={touchedType && errorType}
                              error={touchedType && !!errorType}
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Полный текст адреса">
                            <Field
                              multiline
                              rows={1}
                              as={TextAreaAutoSize}
                              name={`${name}.${index}.fullAddress`}
                              placeholder="Введите полный текст адреса"
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Город или населённый пункт">
                            <Field
                              as={TextField}
                              name={`${name}.${index}.city`}
                              placeholder="Введите город или населённый пункт"
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Район">
                            <Field
                              as={TextField}
                              name={`${name}.${index}.district`}
                              placeholder="Введите район"
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Административный субъект страны (регион)">
                            <Field
                              as={TextField}
                              name={`${name}.${index}.region`}
                              placeholder="Выберите субъект страны (регион)"
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Почтовый индекс">
                            <Field
                              as={TextField}
                              name={`${name}.${index}.postalCode`}
                              placeholder="Выберите страну"
                            />
                          </FormFieldEdit>

                          <FormFieldEdit required label="Страна">
                            <Field
                              as={FormSelect}
                              options={countries}
                              name={`${name}.${index}.country`}
                              helperText={touchedCountry && errorCountry}
                              error={touchedCountry && !!errorCountry}
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Номер квартиры или офиса">
                            <Field
                              as={TextField}
                              name={`${name}.${index}.flat`}
                              placeholder="Введите номер квартиры или офиса"
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Корпус или строение">
                            <Field
                              as={TextField}
                              name={`${name}.${index}.building`}
                              placeholder="Введите корпус или строение"
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Номер дома">
                            <Field
                              as={TextField}
                              name={`${name}.${index}.house`}
                              placeholder="Введите номер дома"
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Улица">
                            <Field
                              as={TextField}
                              name={`${name}.${index}.street`}
                              placeholder="Введите улицу"
                            />
                          </FormFieldEdit>
                        </EditWrapper>
                      </FormChunk>
                    );
                  })
                : null}

              <div>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<IconPlus />}
                  onClick={() => push({ ...initialValue, id: uuidv4() })}
                >
                  Добавить
                </Button>
              </div>
            </EditWrapper>
          </FormBlock>
        );
      }}
    </FieldArray>
  );
};
