import React from 'react';
import { StyledTitle } from '@components/title-page/styles';

type Props = {
  id?: string | number;
  title: string;
  createName: string;
};
export const TitlePage: React.FC<Props> = ({
  id,
  title = 'Название страницы',
  createName = 'Создание',
}) => {
  if (id) {
    return (
      <>
        {title}&nbsp;
        <StyledTitle>#{id}</StyledTitle>
      </>
    );
  } else {
    return <>{createName}</>;
  }
};
