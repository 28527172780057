import { EducationOrganization } from '@declarations/education-organization';
import React, { FC, useState } from 'react';
import { GeneralView } from '@pages/educational-organizations/organization/components/view/general';
import { Form, Formik, FormikProps } from 'formik';
import { getValidationSchema } from '@pages/educational-organizations/organization/create';
import { AddressesView } from '@pages/educational-organizations/organization/components/view/addresses';
import { IdentifiersView } from '@components/form-elements/identifiers';
import { ContactsView } from '@components/form-elements/contacts';
import { GeneralEdit } from '@pages/educational-organizations/organization/components/edit/general';
import { IdentifiersEdit } from '@pages/educational-organizations/organization/components/edit/identifiers';
import { ContactEdit } from '@pages/educational-organizations/organization/components/edit/contact';
import { AddressesEdit } from '@pages/educational-organizations/organization/components/edit/addresses';
import { ControlPanel } from '@components/control-panel';
import { StyledFormWrapper } from '@pages/profile-page/forms/styles';
import { useEditOrganization } from '@queries/education-organization';
import { ModalClose } from '@components/modal-close';
import { ROUTES } from '@constants/routes';
import { useNavigate } from 'react-router-dom';

type Props = {
  edit: boolean;
  back: () => void;
  data?: EducationOrganization;
};
export const OrganizationView: FC<Props> = ({ data, edit, back }) => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const editOrg = useEditOrganization({
    onSuccess: () => {
      back();
    },
  });
  const submitForm = (values: EducationOrganization) => {
    editOrg.mutate(values);
  };

  return (
    <>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={data || ({} as EducationOrganization)}
        validationSchema={getValidationSchema}
        onSubmit={submitForm}
      >
        {({ isValid, values }: FormikProps<EducationOrganization>) => {
          return (
            <Form>
              <StyledFormWrapper>
                {edit ? <GeneralEdit /> : <GeneralView />}
                {edit ? (
                  <IdentifiersEdit name="organizationIdentifiers" />
                ) : (
                  <IdentifiersView data={values.organizationIdentifiers} />
                )}

                {edit ? (
                  <ContactEdit name="contactPoints" />
                ) : (
                  <ContactsView data={values.contactPoints} />
                )}
                {edit ? (
                  <AddressesEdit name="organizationAddresses" />
                ) : (
                  <AddressesView />
                )}
              </StyledFormWrapper>

              {edit && (
                <ControlPanel
                  cancelFn={() => setOpenModal(true)}
                  disabledAction={!isValid}
                  actionName="Сохранить"
                  actionFn={() => submitForm(values)}
                />
              )}
            </Form>
          );
        }}
      </Formik>
      <ModalClose
        open={openModal}
        action={() => navigate(ROUTES.eduOrganizations)}
        handleClose={() => setOpenModal(false)}
        title="Возврат к списку организаций"
        description="Все внесенные данные удалятся без сохранения, продолжить?"
      />
    </>
  );
};
