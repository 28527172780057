import { styled, css } from '@mui/material/styles';
import { PushProps } from '@components/ui/push/index';

export const StyledPush = styled('div')<PushProps>(
  ({ size, horizontal }) => css`
    padding-top: ${!horizontal && size}px;
    padding-right: ${horizontal && size}px;
  `
);

StyledPush.defaultProps = {
  size: 16,
};
