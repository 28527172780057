import { FC } from 'react';
import { useGetAdmin } from '@queries/profiles';
import { AdminForm } from './admin-form';

type Props = {
  adminId?: number;
  needToSubmit: boolean;
  setNeedToSubmit: (needToSubmit: boolean) => void;
};

export const AdminProfile: FC<Props> = ({
  adminId,
  needToSubmit,
  setNeedToSubmit,
}) => {
  const { data: adminFromApi, status } = useGetAdmin({ adminId });

  return adminId ? (
    <AdminForm
      data={adminFromApi}
      isLoading={status === 'loading'}
      needToSubmit={needToSubmit}
      setNeedToSubmit={setNeedToSubmit}
    />
  ) : (
    <AdminForm needToSubmit={needToSubmit} setNeedToSubmit={setNeedToSubmit} />
  );
};
