import { css, styled } from '@mui/material/styles';

export const PageWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    min-height: inherit;
  `
);
export const PageContentWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 24px 32px;
    gap: 40px;
    padding-top: 32px;
  `
);
