import React, { FC, ReactNode, useMemo } from 'react';
import { MenuProps } from '@mui/material/Menu';
import {
  StyledMenuFooter,
  StyledMenuHeader,
  StyledMenuItem,
  StyledSelect,
} from '@components/ui/select/styles';
import { IconArrowDown, IconCheck } from '@components/icons';

export type SelectValueType = string | number;

export type SelectOption = {
  label: string;
  value: SelectValueType;
};

type Props = {
  value: SelectValueType[];
  options: SelectOption[];
  handleChange: (value: SelectValueType[]) => void;
  menuHeader?: ReactNode;
  menuFooter?: ReactNode;
  asButtonLabel?: string;
  menuWidth?: number;
  menuHeight?: number;
};

const MultiSelectComponent: FC<Props> = ({
  value,
  options,
  handleChange,
  menuHeader,
  menuFooter,
  asButtonLabel,
  menuWidth = 272,
  menuHeight = 366,
}) => {
  const MenuProps: Partial<MenuProps> = useMemo(
    () => ({
      PaperProps: {
        style: {
          maxHeight: menuHeight,
          width: menuWidth,
          marginTop: 12,
        },
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      classes: { paper: 'select-paper' },
    }),
    [menuWidth, menuHeight]
  );

  return (
    <StyledSelect
      multiple
      displayEmpty
      value={value}
      onChange={({ target }) => {
        handleChange(target.value as SelectValueType[]);
      }}
      MenuProps={MenuProps}
      {...(asButtonLabel && { renderValue: () => asButtonLabel })}
      IconComponent={IconArrowDown}
    >
      {menuHeader && <StyledMenuHeader>{menuHeader}</StyledMenuHeader>}
      {options.map(({ label, value }) => (
        <StyledMenuItem key={value} value={value}>
          <IconCheck />
          {label}
        </StyledMenuItem>
      ))}
      {menuFooter && <StyledMenuFooter>{menuFooter}</StyledMenuFooter>}
    </StyledSelect>
  );
};

export default MultiSelectComponent;
