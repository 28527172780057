import { IconRequired } from '@components/icons';
import { css, styled } from '@mui/material/styles';
import { FC } from 'react';

type Props = {
  label: string;
  name?: string;
  type?: string;
  renderField?: (props: any) => JSX.Element;
  children: React.ReactNode;
  required?: boolean;
};

const FormFieldEditWrapper = styled('div')(
  () => css`
    display: grid;
    grid-template-columns: 1fr 364px;
    grid-template-rows: min-content;
    gap: 24px;
  `
);

const InfoFieldLabel = styled('div')(
  () => css`
    display: flex;
    align-items: center;
  `
);

const StyledIcon = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-top: -5px;
`;

export const FormFieldEdit: FC<Props> = ({ label, children, required }) => {
  return (
    <FormFieldEditWrapper>
      <InfoFieldLabel>
        {label}
        {required && (
          <StyledIcon>
            <IconRequired />
          </StyledIcon>
        )}
      </InfoFieldLabel>
      {children}
    </FormFieldEditWrapper>
  );
};
