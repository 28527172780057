import { Nullable } from '.';

export type AccountStatus = 'PENDING' | 'ACTIVE' | 'BLOCKED' | 'RETIRED' | '';
export type EsiaType = 'SIMPLE' | 'STANDARD' | 'CONFIRMED' | '';
export type StructuralRole =
  | 'NEXTOFKIN'
  | 'PUPIL'
  | 'ADMIN'
  | 'OPERATOR'
  | 'EDUCATION'
  | '';

export type AccountIdentifierType =
  | 'SNILS'
  | 'OGRNIP'
  | 'BIRTH_CERTIFICATE'
  | 'FOREIGN_PASSPORT'
  | 'PASSPORT'
  | 'BIRTH_CERTIFICATE_FOREIGN'
  | '';

export interface AccountIdentifier {
  id?: number;
  type: AccountIdentifierType;
  childDocId?: number;
  identifier: string;
  periodEnd?: string;
  periodStart?: string;
  personId?: number;
  system?: string;
}

export type ContactType =
  | 'TELEPHONE'
  | 'FAX'
  | 'EMAIL'
  | 'PAGER'
  | 'URL'
  | 'SMS'
  | 'OTHER'
  | '';
export interface ContactPoint {
  id?: number;
  type: ContactType;
  esiaId?: string;
  organizationId?: number;
  personId?: number;
  contact: string;
  use: string;
  rank: string;
}

export interface PersonData {
  active?: boolean;
  birthDate?: string;
  esiaId?: string;
  gender?: string;
  nameGiven: string;
  nameFamily: string;
  nameMiddle: string;
}

export type DTOProps = AccountIdentifier | ContactPoint;

export interface UserData {
  accountStatus?: AccountStatus;
  adminProfileId?: number;
  consentStatus?: string;
  controllerProfileId?: number;
  createdByAdminId?: number;
  esiaType?: EsiaType;
  person?: PersonData;
  identifiersDTOList: AccountIdentifier[];
  contactPointDTOList: ContactPoint[];
  isDeleted?: boolean;
  juvenileStatus?: string;
  nextOfKinProfileId?: number;
  periodEnd?: string;
  periodStart?: string;
  psychologistProfileId?: number;
  pupilProfileId?: number;
}

export type User = UserData & { id: number };

export enum ESource {
  esia = 'есиа',
  admin = 'админ',
  user = 'пользователь',
  rosobrnadzor = 'рособрнадзор',
  egrul = 'егрюл',
  rosstat = 'росстат',
  other = 'другой',
}

export enum EStatus {
  pending = 'PENDING',
  active = 'ACTIVE',
  blocked = 'BLOCKED',
  retired = 'RETIRED',
}

export type TAccountListItem = {
  id: number;
  source: ESource;
  nameFamily: string;
  nameGiven: string;
  nameMiddle: string;
  accountStatusEnumDTO: EStatus;
  adminProfileId: Nullable<number>;
  psychologistProfileId: Nullable<number>;
  pupilProfileId: Nullable<number>;
  nextOfKinProfileId: Nullable<number>;
  controllerProfileId: Nullable<number>;
};
