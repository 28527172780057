import { Psychologist } from '@declarations/profiles/psychologist';
import { useEditPsychologistMutation } from '@queries/profiles';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { GeneralEdit } from './general';
import { StyledFormWrapper } from '../styles';
import { KeyedPsychologistRole, RolesEdit } from './roles';

type Props = {
  data?: Psychologist;
  isLoading?: boolean;
  needToSubmit?: boolean;
  setNeedToSubmit: (needToSubmit: boolean) => void;
};

function getInitialState(): Psychologist {
  return {
    id: null,
    source: null,
    status: 'CONFIRMED',
    roles: [],
  };
}

export const PsychologistForm: FC<Props> = ({
  data,
  needToSubmit,
  setNeedToSubmit,
}) => {
  const editMutation = useEditPsychologistMutation();
  const formik = useFormik({
    initialValues: data || getInitialState(),
    onSubmit: (values) => {
      const state = { ...values };
      state.roles = state.roles.map((item) => {
        const { key, ...rest } = item as KeyedPsychologistRole;
        return rest;
      });

      editMutation.mutate(state);
      setNeedToSubmit(false);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (needToSubmit) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToSubmit]);
  return (
    <FormikProvider value={formik}>
      <Form>
        <StyledFormWrapper>
          <GeneralEdit />
          <RolesEdit name="roles" />
        </StyledFormWrapper>
      </Form>
    </FormikProvider>
  );
};
