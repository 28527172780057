import { FC } from 'react';
import { Button, IconButton, TextField } from '@mui/material';
import { FormBlock } from '@components/form-block/form-block';
import FormChunk from '@components/form-chunk/form-chunk';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import { FormSelect } from '@components/form-select/form-select';
import { IconPlus, IconTrash } from '@components/icons';
import { AccountIdentifier } from '@declarations/user';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps } from 'formik';
import { EditWrapper } from '../styles';

type Props = {
  name: string;
};

export type KeyedAccountIdentifier = AccountIdentifier & { key?: number };

const identifierTypeOptions = [
  { value: 'SNILS', label: 'СНИЛС' },
  { value: 'OGRNIP', label: 'ОГРНИП' },
  { value: 'BIRTH_CERTIFICATE', label: 'Свидетельство о рождении' },
  { value: 'FOREIGN_PASSPORT', label: 'Заграничный паспорт' },
  { value: 'PASSPORT', label: 'Паспорт' },
  {
    value: 'BIRTH_CERTIFICATE_FOREIGN',
    label: 'Заграничное свидетельство о рождении',
  },
];

function getInitialIdentifier(freeId: number): {
  key: number;
  type: string;
  identifier: string;
} {
  return { key: freeId, type: '', identifier: '' };
}

export const IdentifiersEdit: FC<Props> = ({ name }) => {
  return (
    <FieldArray name={name}>
      {({ form, push, remove }: FieldProps & FieldArrayRenderProps) => {
        const identifiers = form.getFieldProps(name).value;
        return (
          <FormBlock expandable title="Идентификаторы">
            <EditWrapper>
              {identifiers.length > 0
                ? identifiers.map(
                    (item: KeyedAccountIdentifier, index: number) => (
                      <FormChunk
                        expandable
                        title="Идентификатор"
                        index={index + 1}
                        key={item.id || item.key}
                        isEdit
                        deleteButton={
                          <IconButton onClick={() => remove(index)}>
                            <IconTrash />
                          </IconButton>
                        }
                      >
                        <EditWrapper>
                          <FormFieldEdit label="Тип идентификатора">
                            <Field
                              name={`${name}.${index}.type`}
                              as={FormSelect}
                              options={identifierTypeOptions}
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Значение идентификатора">
                            <Field
                              name={`${name}.${index}.identifier`}
                              as={TextField}
                            />
                          </FormFieldEdit>
                        </EditWrapper>
                      </FormChunk>
                    )
                  )
                : 'Идентификаторы отсутствуют'}

              <div>
                <Button
                  variant="outlined"
                  onClick={() =>
                    push(getInitialIdentifier(identifiers.length + 1))
                  }
                  startIcon={<IconPlus />}
                >
                  Добавить
                </Button>
              </div>
            </EditWrapper>
          </FormBlock>
        );
      }}
    </FieldArray>
  );
};
