import { endPoints } from '@api/end-points';
import { axiosInstance } from '@api/instances';
import { TApiResponse } from '@declarations/index';
import { DictionaryEnum } from '@hooks/get-dictionary';

const url = endPoints.dictionary;

type TDictionary = TApiResponse<string | null[]>;

const fetchData = async (endpoint: DictionaryEnum) => {
  const response = await axiosInstance.get<TDictionary>(`${url}/${endpoint}`);
  return response.data.data;
};

export const dictionaryApi = {
  structuralroletype: () => fetchData(DictionaryEnum.StructuralRoleType),
  pupilattendanceform: () => fetchData(DictionaryEnum.PupilAttendanceForm),
  organizationtype: () => fetchData(DictionaryEnum.OrganizationType),
  organizationidentifiertype: () =>
    fetchData(DictionaryEnum.OrganizationIdentifierType),
  juvenilestatus: () => fetchData(DictionaryEnum.JuvenileStatus),
  eduorganizationgrouptype: () =>
    fetchData(DictionaryEnum.EduOrganizationGroupType),
  contacttype: () => fetchData(DictionaryEnum.ContactType),
  consentStatus: () => fetchData(DictionaryEnum.ConsentStatus),
  addressType: () => fetchData(DictionaryEnum.AddressType),
  accountstatus: () => fetchData(DictionaryEnum.AccountStatus),
  accountidentifiertype: () => fetchData(DictionaryEnum.AccountIdentifierType),
};
