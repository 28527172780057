import { Box, CardContent, CardHeader, Collapse } from '@mui/material';
import { ExpandableTitle } from '@components/form-expandable-title/form-expandable-title';
import React, { FC, useState } from 'react';
import { StyledCard } from './styles';

type FormChunkProps = {
  expandable?: boolean;
  children: React.ReactNode;
  isEdit?: boolean;
  deleteButton?: React.ReactNode;
  title?: string;
  index?: number;
  isExpanded?: boolean;
};

const FormChunk: FC<FormChunkProps> = ({
  title,
  index,
  children,
  isEdit,
  deleteButton,
  expandable = false,
  isExpanded = false,
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const handleExpanded = () => {
    setExpanded((prevState) => !prevState);
  };

  function getTitle(): string {
    return index ? `${title} #${index}` : `${title}`;
  }

  return (
    <StyledCard expanded={expanded}>
      {expandable && (
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between">
              <ExpandableTitle
                title={getTitle()}
                onClick={handleExpanded}
                expanded={expanded}
                typographyProps={{ variant: 'h4' }}
              />
              {isEdit && deleteButton}
            </Box>
          }
        />
      )}

      <CardContent
        sx={{
          borderTop: expanded ? '1px solid' : '',
          borderTopColor: expanded ? 'extra.breakBorderLight' : '',
        }}
      >
        {expandable ? (
          <Collapse in={expanded} timeout={0}>
            {children}
          </Collapse>
        ) : (
          children
        )}
      </CardContent>
    </StyledCard>
  );
};

export default FormChunk;
