import { styled, css } from '@mui/material/styles';
import { MenuItem, Select } from '@mui/material';

export const StyledSelect = styled(Select)(
  ({ theme }) => css`
    border-radius: 12px;
    color: ${theme.palette.grey['600']};

    .MuiSelect-select {
      padding-left: 16px !important;
      padding-right: 40px !important;
      border-radius: inherit;

      &[aria-expanded='true'] {
        background-color: ${theme.palette.primary.light};

        &,
        ~ * {
          color: ${theme.palette.primary.dark};
        }

        ~ .MuiOutlinedInput-notchedOutline {
          border-color: ${theme.palette.primary.xLight} !important;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.grey['400']} !important;
    }

    svg {
      right: 14px;
      color: inherit;
    }
  `
);

export const StyledMenuHeader = styled('div')(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    z-index: 1;
    margin: -4px 4px 0;
    padding: 16px 16px;
    border-bottom: 1px solid ${theme.palette.grey['300']};
    background-color: ${theme.palette.common.white};
  `
);

export const StyledMenuFooter = styled('div')(
  ({ theme }) => css`
    position: sticky;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    margin: 0 4px -4px;
    padding: 16px 16px;
    border-top: 1px solid ${theme.palette.grey['300']};
    background-color: ${theme.palette.common.white};
  `
);

export const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    padding: 10px 16px;
    border-radius: 4px;

    svg {
      margin-right: 8px;
    }

    &.Mui-selected {
      color: ${theme.palette.primary.dark};
      background-color: ${theme.palette.primary.light};

      svg {
        color: ${theme.palette.primary.xLight};
      }
    }
  `
);
