import React, { FC, ReactNode } from 'react';
import Header, { HeaderProps } from '@components/header';
import { StyledContent } from '@pages/main-page/styles';

type Props = HeaderProps & {
  children: ReactNode;
};

const PageContent: FC<Props> = ({ children, ...headerProps }) => {
  return (
    <div>
      <Header {...headerProps} />
      <StyledContent>{children}</StyledContent>
    </div>
  );
};

export default PageContent;
