import React, { FC } from 'react';
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  getIn,
  useFormikContext,
} from 'formik';
import { FormBlock } from '@components/form-block/form-block';
import { EditWrapper } from '@pages/profile-page/forms/styles';
import FormChunk from '@components/form-chunk/form-chunk';
import { Button, IconButton, TextField } from '@mui/material';
import { IconPlus, IconTrash } from '@components/icons';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';

import {
  EducationOrganization,
  OrganizationIdentifierDTO,
} from '@declarations/education-organization';
import { FormSelect } from '@components/form-select/form-select';
import { getRandomNumber } from '@utils/index';

type TInitialValue = Pick<
  OrganizationIdentifierDTO,
  'id' | 'type' | 'identifier'
>;

const initialValue: TInitialValue = {
  id: 0,
  type: '',
  identifier: '',
};

type Props = {
  name: string;
};

const list = [
  {
    value: 1,
    label: 'Идентификационный номер налогоплательщика',
  },
];

export const IdentifiersEdit: FC<Props> = ({ name }) => {
  const { touched, errors } = useFormikContext<EducationOrganization>();

  return (
    <FieldArray name={name}>
      {({ form, push, remove }: FieldProps & FieldArrayRenderProps) => {
        const identifiers: OrganizationIdentifierDTO[] =
          form.getFieldProps(name).value;

        return (
          <FormBlock expandable title="Идентификаторы">
            <EditWrapper>
              {identifiers?.length > 0
                ? identifiers?.map((item, index: number) => {
                    const identifier = `${name}[${index}].identifier`;
                    const touchedIdentifier = getIn(touched, identifier);
                    const errorIdentifier = getIn(errors, identifier);

                    return (
                      <FormChunk
                        expandable
                        title="Идентификатор"
                        index={index + 1}
                        key={item.id}
                        isEdit
                        deleteButton={
                          <IconButton onClick={() => remove(index)}>
                            <IconTrash />
                          </IconButton>
                        }
                      >
                        <EditWrapper>
                          <FormFieldEdit required label="Тип идентификатора">
                            <Field
                              name={`${name}.${index}.type`}
                              as={FormSelect}
                              options={list}
                            />
                          </FormFieldEdit>

                          <FormFieldEdit
                            required
                            label="Значение идентификатора"
                          >
                            <Field
                              as={TextField}
                              name={`${name}.${index}.identifier`}
                              placeholder="Введите значение идентификатора"
                              helperText={
                                touchedIdentifier ? errorIdentifier : ''
                              }
                              error={Boolean(
                                touchedIdentifier && errorIdentifier
                              )}
                            />
                          </FormFieldEdit>
                        </EditWrapper>
                      </FormChunk>
                    );
                  })
                : null}

              <div>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<IconPlus />}
                  onClick={() =>
                    push({ ...initialValue, id: getRandomNumber() })
                  }
                >
                  Добавить
                </Button>
              </div>
            </EditWrapper>
          </FormBlock>
        );
      }}
    </FieldArray>
  );
};
