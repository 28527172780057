import { FormBlock } from '@components/form-block/form-block';
import FormChunk from '@components/form-chunk/form-chunk';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import { FormSelect } from '@components/form-select/form-select';
import { TextField } from '@mui/material';
import { Field } from 'formik';
import { EditWrapper } from '../styles';

const esiaOptions = [
  { value: 'SIMPLE', label: 'Простая' },
  { value: 'STANDARD', label: 'Стандартная' },
  { value: 'CONFIRMED', label: 'Подтверждённая' },
];
const accountStatusOptions = [
  { value: 'PENDING', label: 'В обработке' },
  { value: 'ACTIVE', label: 'Действует' },
  { value: 'BLOCKED', label: 'Заблокирована' },
  { value: 'RETIRED', label: 'Удалена' },
];

export const GeneralEdit = () => {
  return (
    <FormBlock title="Общие сведения">
      <FormChunk>
        <EditWrapper>
          <FormFieldEdit label="Фамилия">
            <Field name="person.nameFamily" as={TextField} placeholder="asd" />
          </FormFieldEdit>
          <FormFieldEdit label="Имя">
            <Field name="person.nameGiven" as={TextField} />
          </FormFieldEdit>
          <FormFieldEdit label="Отчество">
            <Field name="person.nameMiddle" as={TextField} />
          </FormFieldEdit>

          <FormFieldEdit label="Тип уч. записи в ЕСИА">
            <Field
              name="esiaType"
              as={FormSelect}
              options={esiaOptions}
              placeholder="Выбрать"
            />
          </FormFieldEdit>
          <FormFieldEdit label="Статус учетной записи">
            <Field
              name="accountStatus"
              as={FormSelect}
              options={accountStatusOptions}
              placeholder="Выбрать"
            />
          </FormFieldEdit>
        </EditWrapper>
      </FormChunk>
    </FormBlock>
  );
};
