import { Select, MenuProps, Typography } from '@mui/material';
import { FieldProps } from 'formik';
import { FC, useMemo, useRef, useEffect, useState } from 'react';
import { StyledMenuItem } from './styles';

export type OptionProps = {
  value: string;
  label: string;
};
type Props = {
  options: OptionProps[];
  placeholder?: string;
} & FieldProps;

export const FormSelect: FC<Props> = ({
  field,
  form,
  placeholder = 'Выбрать',
  options,
  ...props
}) => {
  const selectRef = useRef<HTMLElement>(null);
  const [selectSize, setSelectSize] = useState<number>(0);
  const MenuProps: Partial<MenuProps> = useMemo(
    () => ({
      MenuListProps: {
        style: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      slotProps: {
        paper: {
          style: {
            marginTop: '4px',
            padding: '4px',
            borderRadius: '9px',
            minWidth: selectSize,
          },
        },
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    }),
    [selectSize]
  );
  useEffect(() => {
    setSelectSize(
      selectRef.current ? selectRef.current.getBoundingClientRect().width : 0
    );
  }, []);

  return (
    <Select
      MenuProps={MenuProps}
      renderValue={(value) =>
        !value ? (
          <Typography color="grey.600" variant="p">
            {placeholder}
          </Typography>
        ) : (
          options.find((item) => item.value === value)?.label
        )
      }
      displayEmpty={!!placeholder}
      ref={selectRef}
      {...field}
      {...props}
    >
      {options.length > 0 &&
        options.map((item) => (
          <StyledMenuItem value={item.value} key={item.value}>
            {item.label}
          </StyledMenuItem>
        ))}
    </Select>
  );
};
