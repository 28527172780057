import { FC } from 'react';
import { Button, IconButton, TextField } from '@mui/material';
import { IconPlus, IconTrash } from '@components/icons';
import { FormBlock } from '@components/form-block/form-block';
import FormChunk from '@components/form-chunk/form-chunk';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import { FormSelect } from '@components/form-select/form-select';
import { FormDatePicker } from '@components/form-date-picker/form-date-picker';
import { NextOfKinStructRole } from '@declarations/profiles/next-of-kin';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { EditWrapper } from '../styles';

type Props = {
  name: string;
};

export type KeyedNextOfKinRole = NextOfKinStructRole & { key?: string };

const roleOptions = [
  { value: 'ADMIN', label: 'Родитель' },
  { value: 'admiinib', label: 'Попечитель' },
  { value: 'adminSystem', label: 'Опекун' },
];

function getInitialRole(): KeyedNextOfKinRole {
  return {
    id: null,
    key: uuidv4(),
    source: 'ручками',
    periodStart: '',
    periodEnd: '',
    role: '',
    status: 'CREATED',
    pupilProfileID: null,
    nextOfKinProfileID: null,
    verifiedByAdminID: null,
  };
}

export const RolesEdit: FC<Props> = ({ name }) => {
  return (
    <FieldArray name={name}>
      {({ form, push, remove }: FieldProps & FieldArrayRenderProps) => {
        const roles = form.getFieldProps(name).value;
        return (
          <FormBlock expandable title="Струтурные роли">
            <EditWrapper>
              {roles.length > 0
                ? roles.map((item: KeyedNextOfKinRole, index: number) => (
                    <FormChunk
                      expandable
                      title="Роль"
                      index={index + 1}
                      key={item.id || item.key}
                      isEdit
                      deleteButton={
                        <IconButton onClick={() => remove(index)}>
                          <IconTrash />
                        </IconButton>
                      }
                    >
                      <EditWrapper>
                        <FormFieldEdit label="Структурная роль">
                          <Field
                            name={`${name}.${index}.role`}
                            as={FormSelect}
                            options={roleOptions}
                            placeholder="Выбрать"
                          />
                        </FormFieldEdit>
                        <FormFieldEdit label="Начало периода действия">
                          <Field
                            name={`${name}.${index}.periodStart`}
                            component={FormDatePicker}
                          />
                        </FormFieldEdit>
                        <FormFieldEdit label="Конец периода действия">
                          <Field
                            name={`${name}.${index}.periodEnd`}
                            component={FormDatePicker}
                          />
                        </FormFieldEdit>
                        <FormFieldEdit label="Обучающийся">
                          <Field
                            name={`${name}.${index}.pupilProfileID`}
                            as={TextField}
                          />
                        </FormFieldEdit>
                        <FormFieldEdit label="Статус">
                          <Field
                            name={`${name}.${index}.status`}
                            as={TextField}
                            disabled
                          />
                        </FormFieldEdit>
                      </EditWrapper>
                    </FormChunk>
                  ))
                : 'Роли отсутствуют'}

              <div>
                <Button
                  variant="outlined"
                  onClick={() => push(getInitialRole())}
                  startIcon={<IconPlus />}
                >
                  Добавить
                </Button>
              </div>
            </EditWrapper>
          </FormBlock>
        );
      }}
    </FieldArray>
  );
};
