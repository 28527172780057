import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { profilesApi } from '@api/profiles';
import { MutationLifecycle } from '../types';

export const GET_ADMIN = 'GET_ADMIN';
export const GET_NEXT_OF_KIN = 'GET_NEXT_OF_KIN';
export const GET_PUPIL = 'GET_PUPIL';
export const GET_PSYCHOLOGIST = 'GET_PSYCHOLOGIST';
export const GET_CONTROLLER = 'GET_CONTROLLER';

export function useGetAdmin({ adminId }: { adminId?: number }) {
  return useQuery({
    queryKey: [GET_ADMIN],
    queryFn: () => profilesApi.getAdmin(adminId as number),
    enabled: !!adminId,
  });
}
export function useEditAdminMutation({ onSuccess }: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.editAdmin, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_ADMIN] });
      onSuccess?.();
    },
  });
}
export function useCreateAdminMutation({ onSuccess }: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.createAdmin, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_ADMIN] });
      onSuccess?.();
    },
  });
}

export function useGetNextOfKin({ nextOfKinId }: { nextOfKinId?: number }) {
  return useQuery({
    queryKey: [GET_NEXT_OF_KIN],
    queryFn: () => profilesApi.getNextOfKin(nextOfKinId as number),
    enabled: !!nextOfKinId,
  });
}
export function useEditNextOfKinMutation({
  onSuccess,
}: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.editNextOfKin, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_NEXT_OF_KIN] });
      onSuccess?.();
    },
  });
}
export function useCreateNextOfKinMutation({
  onSuccess,
}: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.createNextOfKin, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_NEXT_OF_KIN] });
      onSuccess?.();
    },
  });
}

export function useGetPupil({ pupilId }: { pupilId?: number }) {
  return useQuery({
    queryKey: [GET_PUPIL],
    queryFn: () => profilesApi.getPupil(pupilId as number),
    enabled: !!pupilId,
  });
}
export function useEditPupilMutation({ onSuccess }: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.editPupil, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_PUPIL] });
      onSuccess?.();
    },
  });
}
export function useCreatePupilMutation({ onSuccess }: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.createPupil, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_PUPIL] });
      onSuccess?.();
    },
  });
}

export function useGetPsychologist({
  psychologistId,
}: {
  psychologistId?: number;
}) {
  return useQuery({
    queryKey: [GET_PSYCHOLOGIST],
    queryFn: () => profilesApi.getPsychologist(psychologistId as number),
    enabled: !!psychologistId,
  });
}
export function useEditPsychologistMutation({
  onSuccess,
}: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.editPsychologist, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_PSYCHOLOGIST] });
      onSuccess?.();
    },
  });
}
export function useCreatePsychologistMutation({
  onSuccess,
}: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.createPsychologist, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_PSYCHOLOGIST] });
      onSuccess?.();
    },
  });
}

export function useGetController({ controllerId }: { controllerId?: number }) {
  return useQuery({
    queryKey: [GET_CONTROLLER],
    queryFn: () => profilesApi.getController(controllerId as number),
    enabled: !!controllerId,
  });
}
export function useEditControllerMutation({
  onSuccess,
}: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.editController, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_CONTROLLER] });
      onSuccess?.();
    },
  });
}
export function useCreateControllerMutation({
  onSuccess,
}: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(profilesApi.createController, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_CONTROLLER] });
      onSuccess?.();
    },
  });
}
