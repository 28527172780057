import { useMutation, useQuery } from '@tanstack/react-query';
import { authApi } from '@api/auth';
import { TLoginResponse } from '@declarations/auth';
import { EToken, tokenManager } from '@utils/token-manager';
import { MutationLifecycle } from '../types';

export const USER_INFO = 'USER_INFO';

export function useLogin({ onSuccess }: MutationLifecycle<TLoginResponse>) {
  return useMutation(authApi.login, {
    onSuccess: (response) => {
      const { accessToken, refreshToken } = response;
      tokenManager.setToken(EToken.access, accessToken);
      tokenManager.setToken(EToken.refresh, refreshToken);
      onSuccess?.();
    },
  });
}

export function useUserInfo() {
  return useQuery({
    queryKey: [USER_INFO],
    queryFn: authApi.userInfo,
    retry: false,
  });
}
