import NavigationBar from '@components/navigation-bar/navigation-bar';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { StyledLayout } from './styles';

const MainPage = () => {
  const [openDrawer, setOpenDrawer] = useState(true);

  return (
    <StyledLayout opendrawer={Number(openDrawer)}>
      <NavigationBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <Outlet />
    </StyledLayout>
  );
};

export default MainPage;
