import { Pagination } from '@mui/material';
import React from 'react';

type Props = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
};

export default function PaginationActions({
  count,
  rowsPerPage,
  onPageChange,
}: Props) {
  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    return onPageChange(e as React.MouseEvent<HTMLButtonElement>, value - 1);
  };
  return (
    <Pagination
      count={Math.ceil(count / rowsPerPage)}
      defaultPage={1}
      onChange={handlePageChange}
    />
  );
}
