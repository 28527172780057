import { styled, css } from '@mui/material/styles';
import { IconButton } from '@mui/material';

export const StyledTableWrapper = styled('div')(
  () => css`
    margin-top: -20px;
    margin-right: -20px;
    margin-left: -20px;
  `
);

export const StyledFlex = styled('div')(
  () => css`
    display: flex;
    align-items: center;
  `
);

export const StyledFlexWrap = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  `
);

export const StyledInputWrapper = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    flex: auto;
    max-width: 400px;

    input {
      padding-right: 88px !important;
    }

    button {
      margin-left: -86px;
    }
  `
);

export const StyledHeaderIcon = styled('div')(
  () => css`
    svg {
      width: 20px;
      height: 20px;
    }
  `
);

export const StyledDropDownButton = styled(IconButton)(
  ({ theme }) => css`
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 4px;

    &:hover {
      background: white;
      color: ${theme.palette.primary.dark};
    }
  `
);

export const StyledGrid = styled('div')(
  () => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  `
);
