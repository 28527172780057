import { css, styled } from '@mui/material/styles';

type StyledActiveIconProps = {
  isActive: boolean;
};

export const ActiveIcon = styled('div')<StyledActiveIconProps>(
  ({ isActive }) =>
    css`
      background-color: ${isActive ? '#21BD4F' : '#F46322'};
      width: 8px;
      height: 8px;
      border-radius: 50%;
    `
);
