import { css, styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 4px;

    &:hover {
      background: white;
      color: ${theme.palette.primary.dark};
    }
  `
);

export const WrapTablePopover = styled('div')<{
  isActive: boolean;
  editColumn: boolean;
}>(
  ({ isActive, editColumn }) => css`
    display: ${isActive && 'flex'};
    justify-content: ${isActive && 'space-between'};
    text-align: ${!isActive && editColumn && 'right'};
  `
);
