import React from 'react';
import { IconChevronDown } from '@components/icons';
import PaginationActions from '@components/ui/pagination/pagination-actions';
import {
  StyledSelectPagination,
  StyledTablePagination,
} from '@components/ui/pagination/styles';
import { TABLE_PAGE_SIZE } from '@constants/index';

type Props = {
  count: number;
  page: number;
  rowsPerPage: number;
  setRowsPerPage: (value: number) => void;
  setPage: (value: number) => void;
};

export function CustomSelectIcon(props: React.SVGProps<SVGSVGElement>) {
  return <IconChevronDown {...props} />;
}
export const Pagination = ({
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}: Props) => {
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledTablePagination
      component="div"
      rowsPerPageOptions={TABLE_PAGE_SIZE}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event: unknown, newPage: number) => setPage(newPage)}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage=""
      labelDisplayedRows={({ count }) => `из ${count} строк`}
      ActionsComponent={PaginationActions}
      showLastButton
      SelectProps={{
        variant: 'outlined',
        IconComponent: CustomSelectIcon,
        sx: StyledSelectPagination,
      }}
    />
  );
};
