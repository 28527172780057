import { Box } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const FormBlockWrapper = styled('div')(
  () => css`
    display: flex;
    gap: 16px;
    flex-direction: column;
  `
);

export const FormBlockTitle = styled(Box)(() => css``);
