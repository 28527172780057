import { FC } from 'react';
import { useGetPupil } from '@queries/profiles';
import { PupilForm } from './pupil-form';

type Props = {
  pupilId?: number;
  needToSubmit: boolean;
  setNeedToSubmit: (needToSubmit: boolean) => void;
};

export const PupilProfile: FC<Props> = ({
  pupilId,
  needToSubmit,
  setNeedToSubmit,
}) => {
  const { data: pupilFromApi, status } = useGetPupil({ pupilId });

  return pupilId ? (
    <PupilForm
      data={pupilFromApi}
      isLoading={status === 'loading'}
      needToSubmit={needToSubmit}
      setNeedToSubmit={setNeedToSubmit}
    />
  ) : (
    <PupilForm needToSubmit={needToSubmit} setNeedToSubmit={setNeedToSubmit} />
  );
};
