import React, { FC } from 'react';
import { StyledPush } from '@components/ui/push/styles';

export type PushProps = {
  size?: number;
  horizontal?: boolean;
};

const Push: FC<PushProps> = (props) => <StyledPush {...props} />;

export default Push;
