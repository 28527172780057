import { css, styled } from '@mui/material/styles';
import { Button, Popover } from '@mui/material';
import { openedDrawerWidth } from '@components/navigation-bar/navigation-bar';

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.grey[400]};
    background-color: ${theme.palette.common.white};
  `
);

export const StyledFiltersPopover = styled(Popover)(
  ({ theme }) => css`
    background-color: rgba(0, 0, 0, 0.8);

    .MuiPaper-root {
      width: calc(100% - ${openedDrawerWidth}px - 32px);
      margin-top: 8px;
      padding: 40px;
      border: 1px solid ${theme.palette.grey[300]};
      border-radius: 32px;
      box-shadow: 0 16px 32px rgba(183, 183, 183, 0.06);
    }
  `
);

export const StyledControls = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    div {
      display: flex;
      gap: 40px;
    }
  `
);
