import React, { FC, ReactNode } from 'react';
import {
  StyledAvatar,
  StyledBody,
  StyledBreadcrumbs,
  StyledControls,
  StyledHeader,
  StyledMedia,
  StyledMediaBody,
} from '@components/header/styles';
import { Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as IconSeparator } from '@assets/icons/header/separator.svg';
import { IconSignOut } from '@components/icons';
import { useUserInfo } from '@queries/auth';
import { EToken, tokenManager } from '@utils/token-manager';
import { ROUTES } from '@constants/routes';

type BreadcrumbsProps = {
  title: string;
  link?: string;
};

export type HeaderProps = {
  title: string | ReactNode;
  controlsLeft?: ReactNode;
  controlsRight?: ReactNode;
  breadcrumbs: BreadcrumbsProps[];
};

const Header: FC<HeaderProps> = ({
  title,
  controlsLeft,
  controlsRight,
  breadcrumbs,
}) => {
  const { data: userInfo } = useUserInfo();
  const navigate = useNavigate();

  function signOut() {
    tokenManager.removeToken(EToken.access);
    tokenManager.removeToken(EToken.refresh);
    navigate(ROUTES.auth);
  }

  return (
    <StyledHeader>
      <StyledBody>
        <div>
          <StyledBreadcrumbs separator={<IconSeparator />}>
            {breadcrumbs.map((item) => (
              <div key={item.title}>
                {item.link ? (
                  <Link to={item.link}>{item.title}</Link>
                ) : (
                  <Typography>{item.title}</Typography>
                )}
              </div>
            ))}
          </StyledBreadcrumbs>
          <Typography variant="h1">{title}</Typography>
        </div>

        {userInfo && (
          <StyledMedia>
            <StyledAvatar>
              {userInfo.person.nameFamily.slice(0, 1)}
              {userInfo.person.nameGiven.slice(0, 1)}
            </StyledAvatar>
            <StyledMediaBody>
              <div>
                <Typography variant="h4DemiBold" color="#424955">
                  {userInfo.person.nameFamily}{' '}
                  {userInfo.person.nameGiven.slice(0, 1)}.
                </Typography>
                <br />
                <Typography variant="captionInput" color="grey.600">
                  Администратор
                </Typography>
              </div>
              <button type="button" onClick={signOut}>
                <IconSignOut />
              </button>
            </StyledMediaBody>
          </StyledMedia>
        )}
      </StyledBody>
      {(controlsLeft || controlsRight) && (
        <StyledControls>
          {controlsLeft || <div />}
          {controlsRight}
        </StyledControls>
      )}
    </StyledHeader>
  );
};

export default Header;
