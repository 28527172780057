import React from 'react';
import FormChunk from '@components/form-chunk/form-chunk';
import { EditWrapper } from '@pages/profile-page/forms/styles';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import { Field, useFormikContext } from 'formik';
import { Switch, TextField } from '@mui/material';
import { FormBlock } from '@components/form-block/form-block';
import { FormSelect } from '@components/form-select/form-select';
import { EducationOrganization } from '@declarations/education-organization';
import { FormDatePicker } from '@components/form-date-picker/form-date-picker';

const typesOrg = [
  { label: 'Организация высшего образования', value: 1 },
  { label: 'Общеобразовательная организация', value: 2 },
];

export const GeneralEdit = () => {
  const { touched, errors, values } = useFormikContext<EducationOrganization>();
  return (
    <FormBlock title="Общие сведения">
      <FormChunk>
        <EditWrapper>
          <FormFieldEdit required label="Полное наименование">
            <Field
              name="name"
              fullWidth
              as={TextField}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              placeholder="Введите полное наименование"
            />
          </FormFieldEdit>

          <FormFieldEdit label="Краткое наименование">
            <Field
              name="alias"
              as={TextField}
              placeholder="Введите краткое наименование"
            />
          </FormFieldEdit>

          <FormFieldEdit required label="Тип организации">
            <Field
              name="type"
              placeholder="Выбрать"
              as={FormSelect}
              options={typesOrg}
            />
          </FormFieldEdit>

          <FormFieldEdit label="ФИО руководителя">
            <Field
              name="executiveName"
              placeholder="Введите ФИО руководителя"
              as={TextField}
            />
          </FormFieldEdit>

          <FormFieldEdit label="Должность руководителя">
            <Field
              name="executivePosition"
              placeholder="Введите должность"
              as={TextField}
            />
          </FormFieldEdit>

          <FormFieldEdit label="Головная организация(у филиала)">
            <Field name="executivePosition" as={FormSelect} options={[]} />
          </FormFieldEdit>

          <FormFieldEdit label="Дата актуальности сведений">
            <Field
              name="effectiveDate"
              as={FormDatePicker}
              value={new Date(values.effectiveDate)}
            />
          </FormFieldEdit>

          <FormFieldEdit label="Признак активности">
            <Field name="active" as={Switch} />
          </FormFieldEdit>
        </EditWrapper>
      </FormChunk>
    </FormBlock>
  );
};
