import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { userApi } from '@api/user';
import { TGetAccountListRequestParams } from '@declarations/api';
import { MutationLifecycle } from '../types';

export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER = 'GET_USER';

export function useGetAccountList(params?: TGetAccountListRequestParams) {
  return useQuery({
    queryKey: [GET_USER_LIST],
    queryFn: () => userApi.getAccountList(params),
    select: ({ data }) => data,
  });
}

export function useGetUser({ id }: { id: number }) {
  return useQuery({
    queryKey: [GET_USER],
    queryFn: () => userApi.getUser(id),
    enabled: !!id,
    select: (data) => {
      data.contactPointDTOList.map((el) => {
        if (!el.rank) {
          el.rank = '';
        }
        if (!el.use) {
          el.use = '';
        }
        return el;
      });
      return data;
    },
  });
}

export function useEditUserMutation({ onSuccess }: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(userApi.editUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_USER] });
      onSuccess?.();
    },
  });
}
export function useCreateUserMutation({ onSuccess }: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(userApi.createUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_USER] });
      onSuccess?.();
    },
  });
}
