import { IconCalendar } from '@components/icons';
import { DatePicker } from '@mui/x-date-pickers';
import { FieldProps } from 'formik';

export const FormDatePicker = ({ field, form, ...props }: FieldProps) => {
  const handleChange = (value: Date | null) => {
    form.setFieldValue(field.name, value?.toISOString());
  };
  return (
    <DatePicker
      value={field?.value ? new Date(field.value) : null}
      onChange={handleChange}
      {...props}
      slots={{ openPickerIcon: () => <IconCalendar /> }}
    />
  );
};
