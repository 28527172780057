import { Box } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const ExpandableTitleWrapper = styled(Box)<{ onClick: () => void }>(
  () => css`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  `
);

export const TitleIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>(
  ({ expanded }) => css`
    display: flex;
    align-items: center;
    transform: ${expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.15s;
  `
);
