/*eslint-disable*/
import { UserData } from '@declarations/user';
import { Form, FormikProvider, useFormik } from 'formik';
import { GeneralEdit } from './general';
import { IdentifiersEdit, KeyedAccountIdentifier } from './identifiers';
import { ContactsEdit, KeyedContactPoint } from './contacts';
import { FC, useEffect } from 'react';
import { StyledFormWrapper } from '../styles';

type Props = {
  data?: UserData;
  needToSubmit?: boolean;
  setNeedToSubmit: (needToSubmit: boolean) => void;
  submitFunction: (values: any) => void;
};

function getInitialState(): UserData {
  return {
    person: {
      nameGiven: '',
      nameFamily: '',
      nameMiddle: '',
    },
    esiaType: '',
    accountStatus: '',
    identifiersDTOList: [],
    contactPointDTOList: [],
  };
}

export const UserForm: FC<Props> = ({
  data,
  needToSubmit,
  setNeedToSubmit,
  submitFunction,
}) => {
  const formik = useFormik({
    initialValues: data || getInitialState(),
    onSubmit: async (values, actions) => {
      const state = { ...values };
      state.contactPointDTOList = state.contactPointDTOList.map((item) => {
        const { key, ...rest } = item as KeyedContactPoint;
        return rest;
      });
      state.identifiersDTOList = state.identifiersDTOList.map((item) => {
        const { key, ...rest } = item as KeyedAccountIdentifier;
        return rest;
      });

      await submitFunction(state);
      setNeedToSubmit(false);
      actions.setSubmitting(false);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (needToSubmit) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToSubmit]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <StyledFormWrapper>
          <GeneralEdit />
          <IdentifiersEdit name="identifiersDTOList" />
          <ContactsEdit name="contactPointDTOList" />
        </StyledFormWrapper>
      </Form>
    </FormikProvider>
  );
};
