import { User } from '@declarations/user';
import { useGetUser } from '@queries/user';
import { FC } from 'react';

type Props = {
  userId: number;
  renderWithData: (data: {
    user?: User;
    status: 'error' | 'success' | 'loading';
  }) => React.ReactNode;
};

export const ProfilePageDataLoader: FC<Props> = ({
  userId,
  renderWithData,
}) => {
  const { data: userFromApi, status } = useGetUser({ id: userId });

  return <>{renderWithData({ user: userFromApi, status })}</>;
};
