export const ROUTES = {
  main: '/',
  auth: '/auth',
  allProfiles: '/profiles',
  profile: '/profile',
  profileItem: '/profile/:userId',
  profileItemEdit: '/profile/:userId/edit',
  profileCreate: '/profile/create',
  eduOrganizations: '/eduOrganizations',
  eduOrganization: '/eduOrganizations/view/:id',
  createEduOrganization: '/eduOrganizations/create',
  settings: 'settings',
  configurations: 'configurations',
};
