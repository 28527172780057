import React, {
  cloneElement,
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Stack } from '@mui/material';
import { StyledButton, StyledPopover } from '@components/ui/drop-down/style';
import { Nullable } from '@declarations/index';

type Props = {
  id: string;
  control: ReactNode;
  items: TDropDownItems[];
};

export type TDropDownItems = {
  icon: ReactNode;
  label: string;
  onClick: () => void;
};

const DropDown: FC<Props> = ({ id, control, items }) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const clonedControl = useMemo(
    () =>
      cloneElement(control as React.ReactElement, {
        onClick: handleOpen,
      }),
    [control, handleOpen]
  );

  return (
    <>
      {clonedControl}
      <StyledPopover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="column">
          {items.map((item) => (
            <StyledButton
              key={item.label}
              startIcon={item.icon}
              fullWidth
              variant="outlined"
              onClick={item.onClick}
            >
              {item.label}
            </StyledButton>
          ))}
        </Stack>
      </StyledPopover>
    </>
  );
};

export default DropDown;
