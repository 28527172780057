import { Admin } from '@declarations/profiles/admin';
import { NextOfKin } from '@declarations/profiles/next-of-kin';
import { Pupil } from '@declarations/profiles/pupil';
import { Psychologist } from '@declarations/profiles/psychologist';
import { Controller } from '@declarations/profiles/controller';
import { endPoints } from './end-points';
import { axiosInstance } from './instances';

type Profiles = Admin | NextOfKin | Pupil | Psychologist | Controller;
type ProfileGetResponse<T> = {
  status: string;
  error: string;
  data: T;
};

const getProfile = async <T>(profileId: number, endpoint: string) => {
  const { data } = await axiosInstance.get<ProfileGetResponse<T>>(
    `${endpoint}/${profileId}`
  );
  return data.data;
};
const createProfile = async <T extends Profiles>(
  profile: T,
  userId: number,
  endpoint: string
) => {
  return await axiosInstance.post<T>(`${endpoint}/${userId}`, profile);
};
const editProfile = async <T extends Profiles>(
  profile: T,
  endpoint: string
) => {
  return await axiosInstance.put<Admin>(`${endpoint}/${profile.id}`, profile);
};

export const profilesApi = {
  getAdmin(adminId: number) {
    return getProfile<Admin>(adminId, endPoints.adminInfo);
  },

  editAdmin(admin: Admin) {
    return editProfile<Admin>(admin, endPoints.adminInfo);
  },

  createAdmin({ admin, userId }: { admin: Admin; userId: number }) {
    return createProfile<Admin>(admin, userId, endPoints.adminInfo);
  },

  getNextOfKin(nextOfKinId: number) {
    return getProfile<NextOfKin>(nextOfKinId, endPoints.nextOfKinInfo);
  },

  editNextOfKin(nextOfKin: NextOfKin) {
    return editProfile(nextOfKin, endPoints.nextOfKinInfo);
  },

  createNextOfKin({
    nextOfKin,
    userId,
  }: {
    nextOfKin: NextOfKin;
    userId: number;
  }) {
    return createProfile(nextOfKin, userId, endPoints.nextOfKinInfo);
  },

  getPupil(pupilId: number) {
    return getProfile<Pupil>(pupilId, endPoints.pupilInfo);
  },

  editPupil(pupil: Pupil) {
    return editProfile<Pupil>(pupil, endPoints.pupilInfo);
  },

  createPupil({ pupil, userId }: { pupil: Pupil; userId: number }) {
    return createProfile<Pupil>(pupil, userId, endPoints.pupilInfo);
  },

  getPsychologist(psychologistId: number) {
    return getProfile<Psychologist>(psychologistId, endPoints.psychologistInfo);
  },

  editPsychologist(psychologist: Psychologist) {
    return editProfile(psychologist, endPoints.psychologistInfo);
  },

  createPsychologist({
    psychologist,
    userId,
  }: {
    psychologist: Psychologist;
    userId: number;
  }) {
    return createProfile(psychologist, userId, endPoints.psychologistInfo);
  },
  getController(controllerId: number) {
    return getProfile<Controller>(controllerId, endPoints.controllerInfo);
  },

  editController(controller: Controller) {
    return editProfile(controller, endPoints.controllerInfo);
  },

  createController({
    controller,
    userId,
  }: {
    controller: Controller;
    userId: number;
  }) {
    return createProfile(controller, userId, endPoints.controllerInfo);
  },
};
