import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { TModal } from '.';

export const InnerWrapModal = styled(Box)<{ size: TModal['size'] }>(
  ({ size }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${size === 'small' ? '467px' : '600px'};
    background-color: white;
    border-radius: 16px;
    padding: 32px;
  `
);

export const Close = styled('div')(
  () => css`
    position: absolute;
    top: 18px;
    right: 20px;
    cursor: pointer;
    font-size: 10px;
    padding: 3px;

    &:hover {
      opacity: 0.8;
    }
  `
);
