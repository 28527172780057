import React, { FC } from 'react';
import { StyledGrid } from '@pages/profiles-page/list/styles';
import FormGroup from '@components/ui/form-group';
import { TextField } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import type { TFilters } from '@pages/profiles-page/list/types';
import Push from '@components/ui/push';
import { FormSelect, OptionProps } from '@components/form-select/form-select';
import { DictionaryEnum, useGetDictionary } from '@hooks/get-dictionary';
import { getStatusText, mapDictionaryLabel } from '@utils/dictionary';
import { EStatus } from '@declarations/user';
import FiltersModal from '@components/filters-modal';

type Props = {
  apply: (values: TFilters) => void;
};

const initialValues: TFilters = {
  id: '',
  lastname: '',
  firstname: '',
  status: null,
};

const Filters: FC<Props> = ({ apply }) => {
  const { accountstatus = [] } = useGetDictionary([
    DictionaryEnum.AccountStatus,
  ]) as { accountstatus: OptionProps[] };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema}
      onSubmit={apply}
    >
      {({ isValid, values, handleSubmit }: FormikProps<TFilters>) => {
        return (
          <FiltersModal reset={() => {}} apply={handleSubmit}>
            <Form>
              <StyledGrid>
                <div>
                  <FormGroup name="id" label="Id">
                    <Field fullWidth as={TextField} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="lastname" label="Фамилия">
                    <Field fullWidth as={TextField} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="firstname" label="Имя">
                    <Field fullWidth as={TextField} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Отчество">
                    <Field fullWidth as={TextField} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="status" label="Статус">
                    <Field
                      fullWidth
                      as={FormSelect}
                      options={mapDictionaryLabel<EStatus>(
                        accountstatus,
                        getStatusText
                      )}
                    />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Структурная роль">
                    <Field fullWidth as={FormSelect} options={[]} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Образовательная организация">
                    <Field fullWidth as={FormSelect} options={[]} />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup name="" label="Группа ОО">
                    <Field fullWidth as={FormSelect} options={[]} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Обучающийся">
                    <Field fullWidth as={FormSelect} options={[]} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Подсистема">
                    <Field fullWidth as={FormSelect} options={[]} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Тип идентификатора">
                    <Field fullWidth as={FormSelect} options={[]} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Значение идентификатора">
                    <Field fullWidth as={TextField} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Тип контактных данных">
                    <Field fullWidth as={FormSelect} options={[]} />
                  </FormGroup>

                  <Push size={24} />
                  <FormGroup name="" label="Контакт">
                    <Field fullWidth as={TextField} />
                  </FormGroup>
                </div>
              </StyledGrid>
            </Form>
          </FiltersModal>
        );
      }}
    </Formik>
  );
};

export default Filters;

export const getValidationSchema = () => objectYup().shape({});
