import { FC } from 'react';
import { useGetController } from '@queries/profiles';
import { ControllerForm } from './controller-form';

type Props = {
  controllerId?: number;
  needToSubmit: boolean;
  setNeedToSubmit: (needToSubmit: boolean) => void;
};

export const ControllerProfile: FC<Props> = ({
  controllerId,
  needToSubmit,
  setNeedToSubmit,
}) => {
  const { data: controllerFromApi, status } = useGetController({
    controllerId,
  });

  return controllerId ? (
    <ControllerForm
      data={controllerFromApi}
      isLoading={status === 'loading'}
      needToSubmit={needToSubmit}
      setNeedToSubmit={setNeedToSubmit}
    />
  ) : (
    <ControllerForm
      needToSubmit={needToSubmit}
      setNeedToSubmit={setNeedToSubmit}
    />
  );
};
