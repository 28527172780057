import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import React, { FC, ReactNode } from 'react';
import { StyledBadge, StyledButton } from './styles';

type Props = {
  icon: ReactNode;
  openDrawer: boolean;
  to: string;
  children: string;
  counter?: number;
};

const NavButton: FC<Props> = ({ children, to, icon, openDrawer, counter }) => {
  return (
    <NavLink to={to}>
      <StyledButton fullWidth startIcon={icon} opendrawer={Number(openDrawer)}>
        {counter && <StyledBadge badgeContent={counter} color="primary" />}
        <Typography variant="caption">{children}</Typography>
      </StyledButton>
    </NavLink>
  );
};

export default NavButton;
