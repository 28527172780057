import { css, styled } from '@mui/material/styles';
import { Button, Popover } from '@mui/material';

export const StyledPopover = styled(Popover)(
  () => css`
    .MuiPaper-root {
      padding: 4px;
      border-radius: 9px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  `
);

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    justify-content: flex-start;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    border: none;
    font-size: ${theme.typography.fontSizeSmall};
    letter-spacing: 0.2px;
    color: ${theme.palette.grey[900]};

    &:hover {
      border: none;
      color: ${theme.palette.primary.dark};
      background-color: ${theme.palette.primary.light};
    }

    .MuiButton-startIcon {
      margin-left: 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  `
);
