import React from 'react';
import { Button } from '@mui/material';
import { Buttons, WhiteOutlinedButton } from './styles';

type Props = {
  cancelName?: string;
  actionName?: string;
  cancelFn: () => void;
  actionFn: () => void;
  disabledAction?: boolean;
};

export const ControlPanel: React.FC<Props> = ({
  cancelName = 'Выйти без сохранения',
  actionName = 'Сохранить',
  cancelFn,
  actionFn,
  disabledAction,
}) => {
  return (
    <Buttons>
      <WhiteOutlinedButton variant="outlined" onClick={() => cancelFn()}>
        {cancelName}
      </WhiteOutlinedButton>
      <Button
        color="success"
        disabled={disabledAction}
        onClick={() => actionFn()}
      >
        {actionName}
      </Button>
    </Buttons>
  );
};
