import { css, styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';

export const Buttons = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 24px;
    border-radius: 16px;
    background-color: ${theme.palette.grey[300]};
  `
);

export const WhiteOutlinedButton = styled(Button)<ButtonProps>(
  () => css`
    background-color: white;
    color: #f46322;
    border: none;
    height: 56px;

    &:hover {
      border: none;
    }
  `
);
