import React from 'react';
import {
  IconBook,
  IconConfigurations,
  IconOrganizations,
  IconSettings,
  IconAccounts,
} from '@components/icons';

export enum SidebarIcon {
  AllProfiles = 'allProfiles',
  Profile = 'profile',
  EduOrganizations = 'eduOrganizations',
  Settings = 'settings',
  Configurations = 'configurations',
}

export const getSidebarIcons = (icon: string): React.ReactNode | null => {
  switch (icon) {
    case SidebarIcon.AllProfiles:
      return <IconAccounts />;

    case SidebarIcon.Profile:
      return <IconBook />;

    case SidebarIcon.EduOrganizations:
      return <IconOrganizations />;

    case SidebarIcon.Settings:
      return <IconSettings />;

    case SidebarIcon.Configurations:
      return <IconConfigurations />;

    default:
      return null;
  }
};
