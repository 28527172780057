import { endPoints } from '@api/end-points';

import {
  EducationOrganization,
  EducationOrganizationList,
} from '@declarations/education-organization';
import { axiosInstance } from '@api/instances';
import { TApiResponse, TPaginationResponse } from '@declarations/index';

const url = endPoints.educationOrganizationApi;

export const educationOrganizationApi = {
  async getOrganizations() {
    const response = await axiosInstance.get<
      TApiResponse<TPaginationResponse<EducationOrganizationList>>
    >(url);

    return response.data.data.content;
  },

  async getOrganization(id: string) {
    const response = await axiosInstance.get<
      TApiResponse<EducationOrganization>
    >(`${url}/${id}`);
    return response.data.data;
  },

  async createOrganization(data: EducationOrganization) {
    return await axiosInstance.post(url, data);
  },

  async editOrganization(data: EducationOrganization) {
    return await axiosInstance.put<TApiResponse<null>>(
      `${url}/${data.id}`,
      data
    );
  },

  async deleteOrganization(id: number) {
    return await axiosInstance.delete(`${url}/${id}`);
  },
};
