import { FC, useEffect, useState } from 'react';
import { ControlPanel } from '@components/control-panel';
import { useEditUserMutation } from '@queries/user';
import { User } from '@declarations/user';
import { ProfilePageTabber } from './profile-page-tabber';
import { UserForm } from './forms/user/user-form';
import { AdminProfile } from './forms/admin/admin-profile';
import { PupilProfile } from './forms/pupil/pupil-profile';
import { NextOfKinProfile } from './forms/next-of-kin/next-of-kin-profile';
import { PageContentWrapper } from './styles';
import { PsychologistProfile } from './forms/psychologist/psychologist-profile';
import { ControllerProfile } from './forms/controller/controller-profile';

type Props = {
  user?: User;
  status?: 'error' | 'success' | 'loading';
};

type ProfileIds =
  | 'adminProfileId'
  | 'nextOfKinProfileId'
  | 'psychologistProfileId'
  | 'pupilProfileId'
  | 'controllerProfileId';

const getActiveProfiles = (data?: User) => {
  const result: string[] = [];
  if (!data) {
    return result;
  }
  const profilesMap = {
    adminProfileId: 'admin',
    nextOfKinProfileId: 'next-of-kin',
    psychologistProfileId: 'psychologist',
    pupilProfileId: 'pupil',
    controllerProfileId: 'controller',
  };

  for (const [key, value] of Object.entries(profilesMap)) {
    if (data[key as ProfileIds]) {
      result.push(value);
    }
  }
  return result;
};

const ProfilePageEdit: FC<Props> = ({ user: userFromApi, status }) => {
  const mutation = useEditUserMutation();
  const [activeProfiles, setActiveProfiles] = useState<string[]>([]);
  const [needToSubmitUser, setNeedToSubmitUser] = useState<boolean>(false);
  const [needToSubmitAdmin, setNeedToSubmitAdmin] = useState<boolean>(false);
  const [needToSubmitNextOfKin, setNeedToSubmitNextOfKin] =
    useState<boolean>(false);
  const [needToSubmitPupil, setNeedToSubmitPupil] = useState<boolean>(false);
  const submitFunction = (values: User) => {
    mutation.mutate(values, {
      onSuccess: () => {},
      onError: () => {},
    });
  };

  const handleCancelEdit = () => {
    // setIsEdit(!isEdit);
  };

  const handleSaveEdit = async () => {
    setNeedToSubmitUser(true);
    setNeedToSubmitAdmin(true);
    setNeedToSubmitNextOfKin(true);
    setNeedToSubmitPupil(true);
  };
  useEffect(() => {
    setActiveProfiles(getActiveProfiles(userFromApi));
  }, [userFromApi]);
  return (
    <ProfilePageTabber
      activeProfiles={activeProfiles}
      setActiveProfiles={setActiveProfiles}
      isEdit={typeof userFromApi !== 'undefined'}
      renderChildrenByTab={(currentTab) => {
        if (status === 'loading') {
          return 'isLoading';
        }
        return (
          <PageContentWrapper>
            <div>
              <div hidden={currentTab !== 'general'}>
                <UserForm
                  data={userFromApi}
                  needToSubmit={needToSubmitUser}
                  setNeedToSubmit={setNeedToSubmitUser}
                  submitFunction={submitFunction}
                />
              </div>
              <div hidden={currentTab !== 'admin'}>
                <AdminProfile
                  adminId={userFromApi?.adminProfileId}
                  needToSubmit={needToSubmitAdmin}
                  setNeedToSubmit={setNeedToSubmitAdmin}
                />
              </div>
              <div hidden={currentTab !== 'next-of-kin'}>
                <NextOfKinProfile
                  nextOfKinId={userFromApi?.nextOfKinProfileId}
                  needToSubmit={needToSubmitNextOfKin}
                  setNeedToSubmit={setNeedToSubmitNextOfKin}
                />
              </div>
              <div hidden={currentTab !== 'pupil'}>
                <PupilProfile
                  pupilId={userFromApi?.pupilProfileId}
                  needToSubmit={needToSubmitPupil}
                  setNeedToSubmit={setNeedToSubmitPupil}
                />
              </div>
              <div hidden={currentTab !== 'psychologist'}>
                <PsychologistProfile
                  psychologistId={userFromApi?.psychologistProfileId}
                  needToSubmit={needToSubmitPupil}
                  setNeedToSubmit={setNeedToSubmitPupil}
                />
              </div>
              <div hidden={currentTab !== 'controller'}>
                <ControllerProfile
                  controllerId={userFromApi?.controllerProfileId}
                  needToSubmit={needToSubmitPupil}
                  setNeedToSubmit={setNeedToSubmitPupil}
                />
              </div>
            </div>
            <ControlPanel
              cancelFn={handleCancelEdit}
              disabledAction={false}
              actionName={userFromApi ? 'Сохранить' : 'Создать'}
              actionFn={handleSaveEdit}
            />
          </PageContentWrapper>
        );
      }}
    />
  );
};

export default ProfilePageEdit;
