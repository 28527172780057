import React from 'react';
import { CircularProgress } from '@mui/material';
import { StyledLoadingWrapper } from './styles';

export const Loading: React.FC = () => {
  return (
    <StyledLoadingWrapper>
      <CircularProgress />
    </StyledLoadingWrapper>
  );
};
