import React, { FC } from 'react';
import { Chip } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { ChipProps } from '@mui/material/Chip/Chip';
import { EChipColor } from '@pages/profiles-page/utils';

type Props = ChipProps;

export const StyledChip: FC<Props> = (props) => {
  const SChip = styled(Chip)(
    ({ theme, color }) => css`
      padding: 4px 8px;
      height: 28px;
      border-radius: 6px;
      letter-spacing: 0.2px;
      color: ${color === EChipColor.success
        ? '#21BD4F'
        : color === EChipColor.error
        ? '#F07300'
        : color === EChipColor.info
        ? '#9340FF'
        : color === EChipColor.warning
        ? '#CC7D07'
        : color === EChipColor.default
        ? '#0C1524'
        : '#0C1524'};
      background: ${color === EChipColor.success
        ? '#D7F8E0'
        : color === EChipColor.error
        ? '#FFECE8'
        : color === EChipColor.info
        ? '#F4EEFC'
        : color === EChipColor.warning
        ? '#FFEECC'
        : color === EChipColor.default
        ? '#F0F2FC'
        : 'transparent'};

      .MuiChip-label {
        padding: 0;
        font-size: ${theme.typography.fontSizeSmall};
      }

      .MuiChip-icon {
        margin-right: 8px;
        margin-left: 4px;
      }
    `
  );

  return <SChip {...props} />;
};
