import { styled, css, TablePagination } from '@mui/material';
import prevIcon from '@assets/icons/table/arrow-left.svg';
import nextIcon from '@assets/icons/table/arrow-right.svg';

export const StyledSelectPagination = {
  maxWidth: '82px',
  borderRadius: '12px',
  marginRight: '8px',
  color: '#93979E',
  marginLeft: 0,
  '.MuiSelect-outlined': {
    padding: '6px 12px',
  },
};

export const StyledTablePagination = styled(TablePagination)<{
  component: string;
}>(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.grey[400]};
    border-bottom: none;

    .MuiTablePagination-toolbar {
      padding: 24px 8px;

      .MuiPagination-root {
        margin-left: auto;
      }

      .MuiInputBase-root {
        min-width: 82px;
        text-align-last: left;
        font-size: 14px;
      }
      
      .MuiSelect-select {
        padding-top: 7px;
        padding-bottom: 7px;
        text-align-last: left;
      }
    }
    
    .MuiPagination-ul {
      li {
        svg {
          display: none;
        }
        
        &:first-of-type {
          .MuiButtonBase-root {
            background: ${`url('${prevIcon}')`} no-repeat center;
          }
        }
        &:last-of-type {
          .MuiButtonBase-root {
            background: ${`url('${nextIcon}')`} no-repeat center;
          }
        }
      }
    }

    .MuiPaginationItem-root {
      width: 47px;
      height: 36px;
      border-radius: 7px;
    },
    
    .Mui-selected {
      color: ${theme.palette.primary.dark};
      background-color: ${theme.palette.primary.light};
      border: 1px solid ${theme.palette.primary.xLight};
    },
  `
);
