const apiUrlWithVersion = 'api/v1';

export const endPoints = {
  auth: `${apiUrlWithVersion}/auth`,
  userInfo: `${apiUrlWithVersion}/account`,
  adminInfo: `${apiUrlWithVersion}/profile/admin`,
  nextOfKinInfo: `${apiUrlWithVersion}/profile/nextOfKin`,
  pupilInfo: `${apiUrlWithVersion}/profile/pupil`,
  psychologistInfo: `${apiUrlWithVersion}/profile/psychologist`,
  controllerInfo: `${apiUrlWithVersion}/profile/controller`,
  educationOrganizationApi: `${apiUrlWithVersion}/organization`,
  dictionary: `${apiUrlWithVersion}/dictionary`,
};
