import FormChunk from '@components/form-chunk/form-chunk';
import { FormFieldView } from '@components/form-field-view/form-field-view';
import { Box } from '@mui/material';
import { replaceInvalidValue } from '@utils/index';
import { FC } from 'react';

type TContact = {
  id: number;
  contact: string;
  type: 'TELEPHONE' | 'FAX' | 'EMAIL' | 'PAGER' | 'URL' | 'SMS' | 'OTHER' | '';
  use: 'домашний' | 'служебный' | 'временный' | 'прежний' | 'мобильный';
  rank: number;
};

type Props = {
  data?: TContact[];
};

export const ContactsView: FC<Props> = ({ data }) => {
  const title = 'Контактные данные';
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {data?.length
        ? data?.map((el, index) => (
            <FormChunk title={title} index={index + 1} key={el.id} expandable>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                gridTemplateRows="auto"
                gap="16px"
              >
                <FormFieldView
                  title="Тип контакта"
                  value={getContact(el.type)}
                />
                <FormFieldView
                  title="Контакт"
                  value={replaceInvalidValue(el.contact)}
                />
                <FormFieldView
                  title="Тип использования"
                  value={replaceInvalidValue(el.use)}
                />
                <FormFieldView
                  title="Приоритет контакта"
                  value={el.rank || '-'}
                />
              </Box>
            </FormChunk>
          ))
        : 'Контактов не обнаружено'}
    </Box>
  );
};

export const getContact = (type: TContact['type']): string => {
  switch (type) {
    case 'TELEPHONE':
      return 'Телефон';
    case 'FAX':
      return 'Факс';
    case 'EMAIL':
      return 'Email';
    case 'PAGER':
      return 'Пайджер';
    case 'URL':
      return 'URL';
    case 'SMS':
      return 'СМС';
    case 'OTHER':
      return 'Другой';
    default:
      return '-';
  }
};
