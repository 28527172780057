import React, { FC, useState } from 'react';
import {
  StyledIndentRow,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
} from '@components/ui/table/styles';
import {
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import { Pagination } from '@components/ui/pagination/pagination';
import { TableColumnType, TableDataType } from '@components/ui/table/types';
import { SortOrder } from '@declarations/index';
import { TABLE_PAGE_SIZE } from '@constants/index';
import { Sorting } from './sorting';
import { getComparator, stableSort } from './utils';

type Props = {
  data: TableDataType[];
  columns: TableColumnType[];
};

export const Table: FC<Props> = ({ data, columns }) => {
  const [order, setOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = useState<string>('name');

  const [rowsPerPage, setRowsPerPage] = useState(TABLE_PAGE_SIZE[0]);

  const [page, setPage] = useState(0);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [data, order, orderBy, page, rowsPerPage]
  );
  return (
    <>
      <TableContainer>
        <MuiTable size="medium">
          <StyledTableHead>
            <TableRow>
              {columns.map(({ id, label, hideSort, width }) => {
                return (
                  <StyledTableCell
                    key={id}
                    width={width}
                    align="left"
                    padding="none"
                    size="medium"
                    variant="head"
                    sortDirection={orderBy === id ? order : false}
                  >
                    {hideSort ? (
                      label
                    ) : (
                      <Sorting
                        id={id}
                        order={order}
                        label={label}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                    )}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </StyledTableHead>

          <TableBody>
            <StyledIndentRow />
            {visibleRows?.map((row) => {
              return (
                <StyledTableRow hover key={row.id.toString()}>
                  {columns.map((item) => {
                    return (
                      <StyledTableCell key={item.id} center={item.textCenter}>
                        {item.render(row)}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>

      <Pagination
        page={page}
        setPage={setPage}
        count={data.length}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
