import React, { useState } from 'react';
import PageContent from '@pages/main-page/content';
import { ROUTES } from '@constants/routes';
import { useParams } from 'react-router-dom';
import { OrganizationView } from '@pages/educational-organizations/organization/view';
import { CreateOrganization } from '@pages/educational-organizations/organization/create';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { StyledBadge } from '@components/navigation-bar/styles';
import { useGetOrganization } from '@queries/education-organization';
import { IconPencil } from '@components/icons';
import { TitlePage } from '@components/title-page';

type RouteParams = {
  id: string;
};

export const EducationalOrganization = () => {
  const { id } = useParams<RouteParams>();

  const [toggleValue, setToggleValue] = useState('general');

  const { data: organization } = useGetOrganization(id);

  const [edit, setEdit] = useState(false);

  const canEdit = id && !edit;

  const back = () => setEdit(false);

  return (
    <PageContent
      breadcrumbs={[
        { title: 'Главная', link: ROUTES.main },
        { title: 'Образовательные организации', link: ROUTES.eduOrganizations },
        { title: 'Новая организация' },
      ]}
      controlsLeft={
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={toggleValue}
          onChange={(ev, newValue) => {
            setToggleValue(newValue);
          }}
        >
          <ToggleButton value="general">
            Общие сведения
            <StyledBadge color="primary" />
          </ToggleButton>
        </ToggleButtonGroup>
      }
      controlsRight={
        canEdit && (
          <Button
            startIcon={<IconPencil width={19} height={19} />}
            onClick={() => setEdit(true)}
          >
            Редактировать
          </Button>
        )
      }
      title={
        <TitlePage
          id={organization?.id}
          title="Организация"
          createName="Создание организации"
        />
      }
    >
      {id ? (
        <OrganizationView back={back} edit={edit} data={organization} />
      ) : (
        <CreateOrganization />
      )}
    </PageContent>
  );
};
