import { styled, css } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const StyledLoginLayout = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: ${theme.palette.background.special};
  `
);

export const StyledPaper = styled(Paper)(
  () => css`
    width: 416px;
    padding: 24px 64px;
    border-radius: 16px;
  `
);
