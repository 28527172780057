import FormChunk from '@components/form-chunk/form-chunk';
import { Box } from '@mui/material';
import React, { FC } from 'react';
import { FormFieldView } from '@components/form-field-view/form-field-view';
import { replaceInvalidValue } from '@utils/index';

type TIdentifiers = {
  id: number;
  type: string;
  identifier: string;
};

type Props = {
  data: TIdentifiers[];
};

export const IdentifiersView: FC<Props> = ({ data }) => {
  const title = 'Идентификатор';
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {data?.length
        ? data?.map((el, index) => (
            <FormChunk expandable title={title} index={index + 1} key={el.id}>
              <Box display="flex" flexDirection="column" gap="16px">
                <FormFieldView
                  title="Тип идентификатора"
                  value={replaceInvalidValue(el.type)}
                />
                <FormFieldView
                  title="Значение идентификатора"
                  value={replaceInvalidValue(el.identifier)}
                />
              </Box>
            </FormChunk>
          ))
        : 'Идентификаторы отсутствуют'}
    </Box>
  );
};
