import { educationOrganizationApi } from '@api/education-organization';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MutationLifecycle } from '@declarations/index';
import { GET_ORGANIZATIONS } from '@pages/educational-organizations/list';

export const GET_ORGANIZATION = 'GET_ORGANIZATION';

export function useGetOrganizations() {
  return useQuery({
    queryKey: [GET_ORGANIZATIONS],
    queryFn: () => educationOrganizationApi.getOrganizations(),
    initialData: [],
  });
}

export const useGetOrganization = (id?: string) => {
  return useQuery(
    [GET_ORGANIZATION],
    async () => {
      if (id) {
        return await educationOrganizationApi.getOrganization(id);
      }
    },
    {
      enabled: !!id,
    }
  );
};

export function useEditOrganization({ onSuccess }: MutationLifecycle = {}) {
  return useMutation(educationOrganizationApi.editOrganization, {
    onSuccess: () => {
      onSuccess?.();
    },
  });
}

export function useCreateOrganization({ onSuccess }: MutationLifecycle = {}) {
  const queryClient = useQueryClient();
  return useMutation(educationOrganizationApi.createOrganization, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_ORGANIZATIONS] });
      onSuccess?.();
    },
  });
}
