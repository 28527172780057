import LoginForm from '@pages/auth-layout/login-form';
import { StyledLoginLayout } from './styles';

const LoginLayout = () => {
  return (
    <StyledLoginLayout>
      <LoginForm />
    </StyledLoginLayout>
  );
};

export default LoginLayout;
