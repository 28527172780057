import { FC } from 'react';
import { useGetNextOfKin } from '@queries/profiles';
import { NextOfKinForm } from './next-of-kin-form';

type Props = {
  nextOfKinId?: number;
  needToSubmit: boolean;
  setNeedToSubmit: (needToSubmit: boolean) => void;
};

export const NextOfKinProfile: FC<Props> = ({
  nextOfKinId,
  needToSubmit,
  setNeedToSubmit,
}) => {
  const { data: nextOfKinFromApi, status } = useGetNextOfKin({ nextOfKinId });

  return nextOfKinId ? (
    <NextOfKinForm
      data={nextOfKinFromApi}
      isLoading={status === 'loading'}
      needToSubmit={needToSubmit}
      setNeedToSubmit={setNeedToSubmit}
    />
  ) : (
    <NextOfKinForm
      needToSubmit={needToSubmit}
      setNeedToSubmit={setNeedToSubmit}
    />
  );
};
