import React, { Children, FC, ReactElement } from 'react';
import {
  StyledClear,
  StyledControl,
  StyledFormGroup,
  StyledLabel,
} from '@components/ui/form-group/styles';
import { useFormikContext } from 'formik';
import { IconClose } from '@components/icons';

type Props = {
  label: string;
  name: string;
  children: ReactElement;
};

const FormGroup: FC<Props> = ({ label, name, children }) => {
  const { setFieldValue, values } = useFormikContext<Record<string, string>>();

  return (
    <StyledFormGroup>
      <StyledLabel htmlFor={label}>{label}</StyledLabel>
      <StyledControl>
        {Children.map(children as ReactElement, (child) =>
          React.cloneElement(child, { id: label, name })
        )}
        <StyledClear
          type="button"
          onClick={() => {
            setFieldValue(name, '');
          }}
          value={values[name]}
        >
          <IconClose />
        </StyledClear>
      </StyledControl>
    </StyledFormGroup>
  );
};

export default FormGroup;
