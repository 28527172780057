import { styled, css } from '@mui/material/styles';
import { Badge, Button, Drawer } from '@mui/material';
import {
  closedDrawerWidth,
  openedDrawerWidth,
} from '@components/navigation-bar/navigation-bar';

export const StyledDrawer = styled(Drawer)<{ opendrawer: number }>(
  ({ theme, opendrawer }) => css`
    .MuiPaper-root {
      width: ${opendrawer ? openedDrawerWidth : closedDrawerWidth}px;
      padding: 24px ${opendrawer ? 24 : 16}px;
      border: none;
      overflow-x: hidden;
      transition: ${theme.transitions.duration.standard}ms !important;
      background-color: ${theme.palette.background.special};
    }
  `
);

export const StyledHead = styled('div')<{ opendrawer: number }>(
  ({ theme, opendrawer }) => css`
    display: flex;
    align-items: center;
    gap: ${opendrawer ? 8 : 0}px;
    margin-bottom: 16px;

    img {
      width: 48px;
      height: 48px;
    }

    button {
      margin-left: ${!opendrawer && -5}px;
      transform: scaleX(${opendrawer ? 1 : -1});
      transition: ${theme.transitions.duration.standard}ms;
      color: ${theme.palette.primary.dark};
    }

    svg {
      display: block;
    }
  `
);

export const StyledButton = styled(Button)<{
  opendrawer: number;
}>(
  ({ theme, opendrawer }) => css`
    justify-content: flex-start;
    min-width: auto;
    margin-top: 8px;
    padding: 10px 16px;
    color: ${theme.palette.grey['800']};
    background-color: transparent;

    .active &,
    &:hover {
      color: ${theme.palette.primary.dark};
      background-color: ${theme.palette.common.white};
    }

    .MuiButton-startIcon {
      margin-right: ${!opendrawer && 0};
      transition: ${theme.transitions.duration.standard}ms;
    }

    .MuiTypography-root {
      opacity: ${opendrawer ? 1 : 0};
      visibility: ${opendrawer ? 'visible' : 'hidden'};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: ${opendrawer ? theme.typography.fontSizeBase : 0};
      transition: ${theme.transitions.duration.standard}ms;
    }

    .MuiBadge-badge {
      opacity: ${opendrawer ? 1 : 0};
      visibility: ${opendrawer ? 'visible' : 'hidden'};
      background-color: ${theme.palette.primary.dark};
    }
  `
);

export const StyledBadge = styled(Badge)(
  ({ theme }) => css`
    order: 2;
    margin-right: 4px;
    background-color: ${theme.palette.primary.dark};

    & + .MuiTypography-root {
      padding-right: 24px;
    }
  `
);
