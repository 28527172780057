import { FC, ReactElement, useEffect } from 'react';
import { useUserInfo } from '@queries/auth';
import { Navigate } from 'react-router-dom';
import { ROUTES as RouteList } from '@constants/routes';
import { Backdrop, CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  children: ReactElement;
};

const ProtectedRoute: FC<Props> = ({ children }) => {
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useUserInfo();

  useEffect(() => {
    if (error) {
      queryClient.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return isLoading ? (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  ) : data && !error ? (
    children
  ) : (
    <Navigate to={RouteList.auth} />
  );
};

export default ProtectedRoute;
