import { Avatar, Breadcrumbs } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const StyledHeader = styled('div')(
  ({ theme }) => css`
    padding: 24px 32px;
    border-bottom: 1px solid ${theme.palette.grey[400]};
  `
);

export const StyledBody = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
  `
);

export const StyledBreadcrumbs = styled(Breadcrumbs)(
  ({ theme }) => css`
    a {
      text-decoration: none;
      color: ${theme.palette.grey['600']};
    }

    a,
    span {
      font-size: ${theme.typography.fontSizeSmall};
    }
  `
);

export const StyledControls = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  `
);

export const StyledMedia = styled('div')(
  () => css`
    display: flex;
    align-items: center;
  `
);

export const StyledMediaBody = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding-left: 12px;

    button {
      width: 24px;
      height: 24px;
      margin-left: 24px;
      color: ${theme.palette.grey[900]};
    }
  `
);

export const StyledAvatar = styled(Avatar)(
  ({ theme }) => css`
    flex: none;
    width: 48px;
    height: 48px;
    background-color: ${theme.palette.primary.main};
  `
);
