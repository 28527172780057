import { TGetAccountListRequestParams } from '@declarations/api';
import { TApiResponse, TPaginationResponse } from '@declarations/index';
import { UserData, User, TAccountListItem } from '@declarations/user';
import { endPoints } from './end-points';
import { axiosInstance } from './instances';

export const userApi = {
  async getAccountList(params?: TGetAccountListRequestParams) {
    const { data } = await axiosInstance.get<
      TApiResponse<TPaginationResponse<TAccountListItem>>
    >(`${endPoints.userInfo}`, { params });
    return data;
  },

  async getUser(id: number) {
    const { data } = await axiosInstance.get<TApiResponse<User>>(
      `${endPoints.userInfo}/${id}`
    );
    return data.data;
  },

  async editUser(user: User) {
    return await axiosInstance.put<TApiResponse<User>>(
      `${endPoints.userInfo}/${user.id}`,
      user
    );
  },

  async createUser(user: UserData) {
    return await axiosInstance.post<TApiResponse<UserData>>(
      `${endPoints.userInfo}`,
      user
    );
  },
};
