import { FC } from 'react';
import { Typography, TypographyProps, useTheme } from '@mui/material';
import { IconChevronDown } from '@components/icons';
import { ExpandableTitleWrapper, TitleIconWrapper } from './styles';

type Props = {
  title: string;
  expanded: boolean;
  onClick: () => void;
  typographyProps?: TypographyProps;
};

export const ExpandableTitle: FC<Props> = ({
  title,
  expanded,
  typographyProps,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <ExpandableTitleWrapper onClick={onClick}>
      <Typography
        {...typographyProps}
        color={expanded ? 'current' : 'primary.dark'}
      >
        {title}
      </Typography>
      <TitleIconWrapper expanded={expanded}>
        <IconChevronDown
          color={expanded ? 'current' : `${theme.palette.primary.dark}`}
        />
      </TitleIconWrapper>
    </ExpandableTitleWrapper>
  );
};
