import { EStatus } from '@declarations/user';
import { OptionProps } from '@components/form-select/form-select';

export const getStatusText = (value: EStatus) => {
  switch (value) {
    case EStatus.pending:
      return 'В ожидании';
    case EStatus.active:
      return 'Активный';
    case EStatus.blocked:
      return 'Заблокированный';
    case EStatus.retired:
      return 'Ушедший на пенсию';
    default:
      return 'Неизвестный';
  }
};

export const mapDictionaryLabel = <T>(
  options: OptionProps[],
  getText: (value: T) => string
) => options.map((item) => ({ ...item, label: getText(item.label as T) }));
