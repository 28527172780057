import { TLoginRequest, TLoginResponse, TUserInfo } from '@declarations/auth';
import { endPoints } from './end-points';
import { axiosAuthInstance } from './instances';

export const authApi = {
  async login(request: TLoginRequest) {
    const { data } = await axiosAuthInstance.post<TLoginResponse>(
      `${endPoints.auth}/login`,
      request
    );
    return data;
  },

  async refresh() {
    const { data } = await axiosAuthInstance.post<TLoginResponse>(
      `${endPoints.auth}/refresh`
    );
    return data;
  },

  async userInfo() {
    const { data } = await axiosAuthInstance.get<TUserInfo>(
      `${endPoints.auth}/tempuserinfo`
    );
    return data;
  },
};
