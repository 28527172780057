import { styled } from '@mui/material/styles';
import { Typography, css } from '@mui/material';
import { FC, ReactNode } from 'react';

type Props = {
  title: string;
  value: ReactNode | string | number;
};
const InfoFieldWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  `
);

export const FormFieldView: FC<Props> = ({ title, value }) => {
  return (
    <InfoFieldWrapper>
      <Typography variant="captionInput" color="grey.600">
        {title}
      </Typography>
      <Typography>{value}</Typography>
    </InfoFieldWrapper>
  );
};
