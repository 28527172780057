export enum EChipColor {
  error = 'error',
  info = 'info',
  warning = 'warning',
  success = 'success',
  default = 'default',
}

export function getChipValues(role: string): {
  color: EChipColor;
  label: string;
} {
  switch (role) {
    case 'adminProfileId':
      return {
        color: EChipColor.error,
        label: 'Администратор',
      };
    case 'psychologistProfileId':
      return {
        color: EChipColor.info,
        label: 'Педагог психолог',
      };
    case 'nextOfKinProfileId':
      return {
        color: EChipColor.warning,
        label: 'Законный представитель',
      };
    case 'controllerProfileId':
      return {
        color: EChipColor.success,
        label: 'Оператор',
      };
    default:
      return {
        color: EChipColor.default,
        label: 'Обучающийся',
      };
  }
}
