import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import qs from 'query-string';

import { AUTH_API_URL, BASE_API_URL } from '@constants/config';
import { EToken, tokenManager } from '@utils/token-manager';
import { authApi } from '@api/auth';
import { endPoints } from '@api/end-points';

// Instances
export const axiosAuthInstance = axios.create({
  baseURL: AUTH_API_URL,
});

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
});

// Interceptors
const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const refresh = config.url === `${endPoints.auth}/refresh`;
  const token = tokenManager.getToken(refresh ? EToken.refresh : EToken.access);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (refresh) {
    tokenManager.removeToken(EToken.access);
    tokenManager.removeToken(EToken.refresh);
  }
  return config;
};

const responseErrorInterceptor = async (error: AxiosError) => {
  if (error.config && error.response && error.response.status === 401) {
    const token = tokenManager.getToken(EToken.refresh);
    if (token) {
      try {
        const { accessToken, refreshToken } = await authApi.refresh();
        tokenManager.setToken(EToken.access, accessToken);
        tokenManager.setToken(EToken.refresh, refreshToken);
        error.config.headers.Authorization = `Bearer ${accessToken}`;
        return axios.request(error.config);
      } catch (err) {
        tokenManager.removeToken(EToken.access);
        tokenManager.removeToken(EToken.refresh);
      }
    }
  }
  return Promise.reject(error);
};

axiosAuthInstance.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error)
);

axiosInstance.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error)
);

axiosAuthInstance.interceptors.response.use((response) => {
  return response;
}, responseErrorInterceptor);

axiosInstance.interceptors.response.use((response) => {
  return response;
}, responseErrorInterceptor);
