import { FC, useState, MouseEvent, useCallback, useMemo } from 'react';
import { Table } from '@components/ui/table';
import { StyledChip } from '@components/ui/chip';
import { IconPencil, IconSettings, IconVerticalDots } from '@components/icons';
import { useNavigate } from 'react-router-dom';
import { TableColumnType } from '@components/ui/table/types';
import { Nullable } from '@declarations/index';
import {
  StyledDropDownButton,
  StyledFlexWrap,
  StyledHeaderIcon,
  StyledTableWrapper,
} from '@pages/profiles-page/list/styles';
import { ActiveIcon } from '@components/ui/chip/active-icon';
import { ROUTES } from '@constants/routes';
import DropDown from '@components/ui/drop-down';
import { EStatus, TAccountListItem } from '@declarations/user';
import { getStatusText } from '@utils/dictionary';
import { getChipValues } from '@pages/profiles-page/utils';

type Props = {
  data: TAccountListItem[];
};

export const TableProfiles: FC<Props> = ({ data }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const columns: TableColumnType[] = useMemo(
    () => [
      {
        id: 'id',
        label: 'ID',
        width: '58px',
        textCenter: 'true',
        render: (item) => item.id,
      },
      {
        id: 'fullName',
        label: 'ФИО',
        width: '25%',
        render: (item) => item.fullName,
      },
      {
        id: 'role',
        label: 'Профиль',
        render: (item) => (
          <StyledFlexWrap>
            {Object.entries(item.roles)
              .filter((item) => !!item[1])
              .map((item) => (
                <StyledChip key={item[0]} {...getChipValues(item[0])} />
              ))}
          </StyledFlexWrap>
        ),
      },
      {
        id: 'status',
        label: 'Статус',
        width: '15%',
        render: (item) => (
          <StyledChip
            icon={<ActiveIcon isActive={item.status === EStatus.active} />}
            label={getStatusText(item.status)}
          />
        ),
      },
      {
        id: 'settings',
        hideSort: true,
        width: '32px',
        label: (
          <StyledHeaderIcon>
            <IconSettings />
          </StyledHeaderIcon>
        ),
        render: (item) => (
          <DropDown
            id={item.id}
            control={
              <StyledDropDownButton>
                <IconVerticalDots />
              </StyledDropDownButton>
            }
            items={[
              {
                icon: <IconPencil />,
                label: 'Редактировать',
                onClick: () => navigate(`${ROUTES.profile}/${item.id}/edit`),
              },
            ]}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleOpen, anchorEl]
  );

  const tableData = useMemo(
    () =>
      data.map((item) => ({
        id: item.id,
        fullName: `${item.nameFamily} ${item.nameGiven} ${item.nameMiddle}`,
        roles: {
          adminProfileId: item.adminProfileId,
          psychologistProfileId: item.psychologistProfileId,
          pupilProfileId: item.pupilProfileId,
          nextOfKinProfileId: item.nextOfKinProfileId,
          controllerProfileId: item.controllerProfileId,
        },
        status: item.accountStatusEnumDTO,
      })),
    [data]
  );

  return (
    <StyledTableWrapper>
      <Table data={tableData} columns={columns} />
    </StyledTableWrapper>
  );
};
