import { Card, CardProps } from '@mui/material';
import { styled, css } from '@mui/material/styles';

interface StyledCardProps extends CardProps {
  expanded: boolean;
}

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) =>
    !['title', 'index', 'expanded'].includes(prop as string),
})<StyledCardProps>(
  ({ expanded, theme }) => css`
    background-color: ${theme.palette.background.special};
    padding: 24px;
    border-radius: 16px;
    box-shadow: none;

    .MuiCardHeader-root {
      padding: ${expanded ? '0 0 16px 0' : 0};
    }

    .MuiCardContent-root {
      padding: 0;
      border-top: none;
      &:last-child {
        padding: 0;
      }
    }
  `
);

StyledCard.defaultProps = {
  elevation: 0,
};
