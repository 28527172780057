import { FormBlock } from '@components/form-block/form-block';
import FormChunk from '@components/form-chunk/form-chunk';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import { TextField } from '@mui/material';
import { Field } from 'formik';
import { EditWrapper } from '../styles';

export const GeneralAdminEdit = () => {
  return (
    <FormBlock title="Общие сведения">
      <FormChunk>
        <EditWrapper>
          <FormFieldEdit label="Статус профиля">
            <Field name="status" as={TextField} disabled />
          </FormFieldEdit>
          <FormFieldEdit label="Основное место работы">
            <Field name="organizationDTO.alias" as={TextField} />
          </FormFieldEdit>
          <FormFieldEdit label="Должность в организации">
            <Field name="position" as={TextField} />
          </FormFieldEdit>
        </EditWrapper>
      </FormChunk>
    </FormBlock>
  );
};
