import React from 'react';
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  getIn,
  useFormikContext,
} from 'formik';
import { FormBlock } from '@components/form-block/form-block';
import { EditWrapper } from '@pages/profile-page/forms/styles';
import FormChunk from '@components/form-chunk/form-chunk';
import { Button, IconButton, TextField } from '@mui/material';
import { IconPlus, IconTrash } from '@components/icons';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import { FormSelect } from '@components/form-select/form-select';
import { v4 as uuidv4 } from 'uuid';

import {
  ContactPointDTO,
  EducationOrganization,
} from '@declarations/education-organization';

type Props = { name: string };

const initialValue = {
  id: '',
  type: '',
  contact: '',
  use: '',
  rank: null,
  esiaId: '',
  personId: 0,
  organizationId: 0,
};

const typeContacts = [
  { value: 1, label: 'Телефон' },
  { value: 2, label: 'Факс' },
];

const typesUse = [
  { value: 1, label: 'Домашний' },
  { value: 2, label: 'Служебный' },
];

export const ContactEdit: React.FC<Props> = ({ name }) => {
  const { touched, errors } = useFormikContext<EducationOrganization>();
  return (
    <FieldArray name={name}>
      {({ form, push, remove }: FieldProps & FieldArrayRenderProps) => {
        const contacts: ContactPointDTO[] = form.getFieldProps(name).value;

        return (
          <FormBlock expandable title="Контактные данные">
            <EditWrapper>
              {contacts?.length > 0
                ? contacts?.map((item, index: number) => {
                    const typeContact = `${name}[${index}].type`;
                    const touchedTypeContact = getIn(touched, typeContact);
                    const errorTypeContact = getIn(errors, typeContact);

                    const contact = `${name}[${index}].contact`;
                    const touchedContact = getIn(touched, contact);
                    const errorContact = getIn(errors, contact);

                    return (
                      <FormChunk
                        expandable
                        title="Контактные данные"
                        index={index + 1}
                        key={item.id}
                        deleteButton={
                          <IconButton onClick={() => remove(index)}>
                            <IconTrash />
                          </IconButton>
                        }
                      >
                        <EditWrapper>
                          <FormFieldEdit required label="Тип контакта">
                            <Field
                              name={`${name}.${index}.type`}
                              as={FormSelect}
                              options={typeContacts}
                              helperText={
                                touchedTypeContact && errorTypeContact
                              }
                              error={touchedTypeContact && !!errorTypeContact}
                            />
                          </FormFieldEdit>

                          <FormFieldEdit required label="Номер контакта">
                            <Field
                              name={`${name}.${index}.contact`}
                              as={TextField}
                              placeholder="Введите номер контакта"
                              helperText={touchedContact && errorContact}
                              error={errorContact && !!touchedContact}
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Тип использования">
                            <Field
                              options={typesUse}
                              as={FormSelect}
                              name={`${name}.${index}.use`}
                            />
                          </FormFieldEdit>

                          <FormFieldEdit label="Приоритет контакта">
                            <Field
                              type="number"
                              as={TextField}
                              name={`${name}.${index}.use`}
                              placeholder="Введите приоритет контакта"
                            />
                          </FormFieldEdit>
                        </EditWrapper>
                      </FormChunk>
                    );
                  })
                : null}

              <div>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<IconPlus />}
                  onClick={() => push({ ...initialValue, id: uuidv4() })}
                >
                  Добавить
                </Button>
              </div>
            </EditWrapper>
          </FormBlock>
        );
      }}
    </FieldArray>
  );
};
