import { FC } from 'react';
import { Button, IconButton, TextField } from '@mui/material';
import { FormBlock } from '@components/form-block/form-block';
import FormChunk from '@components/form-chunk/form-chunk';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import { FormSelect } from '@components/form-select/form-select';
import { IconPlus, IconTrash } from '@components/icons';
import { ContactPoint } from '@declarations/user';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps } from 'formik';
import { EditWrapper } from '../styles';

type Props = {
  name: string;
};

export type KeyedContactPoint = ContactPoint & { key?: number };

const contactTypeOptions = [
  { value: 'TELEPHONE', label: 'Телефон' },
  { value: 'FAX', label: 'Факс' },
  { value: 'EMAIL', label: 'Электронная почта' },
  { value: 'PAGER', label: 'Пейджер' },
  { value: 'URL', label: 'УРЛ' },
  {
    value: 'SMS',
    label: 'СМС',
  },
  {
    value: 'OTHER',
    label: 'Другое',
  },
];

function getInitialContact(freeId: number): {
  key: number;
  type: string;
  contact: string;
  use: string;
  rank: string;
} {
  return { key: freeId, type: '', contact: '', use: '', rank: '' };
}

export const ContactsEdit: FC<Props> = ({ name }) => {
  return (
    <FieldArray name={name}>
      {({ form, push, remove }: FieldProps & FieldArrayRenderProps) => {
        const contacts = form.getFieldProps(name).value;
        return (
          <FormBlock expandable title="Контактные данные">
            <EditWrapper>
              {contacts.length > 0
                ? contacts.map((item: KeyedContactPoint, index: number) => (
                    <FormChunk
                      expandable
                      title="Контактные данные"
                      index={index + 1}
                      key={item.id || item.key}
                      isEdit
                      deleteButton={
                        <IconButton onClick={() => remove(index)}>
                          <IconTrash />
                        </IconButton>
                      }
                    >
                      <EditWrapper>
                        <FormFieldEdit label="Тип контакта">
                          <Field
                            name={`${name}.${index}.type`}
                            as={FormSelect}
                            options={contactTypeOptions}
                          />
                        </FormFieldEdit>
                        <FormFieldEdit label="Тип использования">
                          <Field
                            name={`${name}.${index}.use`}
                            as={TextField}
                            placeholder="asdas"
                          />
                        </FormFieldEdit>
                        <FormFieldEdit label="Контакт">
                          <Field
                            name={`${name}.${index}.contact`}
                            as={TextField}
                          />
                        </FormFieldEdit>
                        <FormFieldEdit label="Приоритет контакта">
                          <Field
                            name={`${name}.${index}.rank`}
                            as={TextField}
                          />
                        </FormFieldEdit>
                      </EditWrapper>
                    </FormChunk>
                  ))
                : 'Идентификаторы отсутствуют'}

              <div>
                <Button
                  variant="outlined"
                  onClick={() => push(getInitialContact(contacts.length + 1))}
                  startIcon={<IconPlus />}
                >
                  Добавить
                </Button>
              </div>
            </EditWrapper>
          </FormBlock>
        );
      }}
    </FieldArray>
  );
};
