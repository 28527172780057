import { FormBlock } from '@components/form-block/form-block';
import FormChunk from '@components/form-chunk/form-chunk';
import { FormFieldEdit } from '@components/form-field-edit/form-field-edit';
import { TextField } from '@mui/material';
import { Field } from 'formik';
import { EditWrapper } from '../styles';

export const GeneralEdit = () => {
  return (
    <FormBlock title="Общие сведения">
      <FormChunk>
        <EditWrapper>
          <FormFieldEdit label="Статус профиля">
            <Field name="status" as={TextField} placeholder="asd" disabled />
          </FormFieldEdit>
        </EditWrapper>
      </FormChunk>
    </FormBlock>
  );
};
