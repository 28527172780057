import React, { FC, MouseEvent, ReactNode, useCallback, useState } from 'react';
import { IconArrowDown, IconArrowUp, IconFilter } from '@components/icons';
import { v4 } from 'uuid';
import { Nullable } from '@declarations/index';
import { StyledButtonLink } from '@components/ui/link/styles';
import { StyledButton, StyledControls, StyledFiltersPopover } from './styles';

type Props = {
  children: ReactNode;
  reset: () => void;
  apply: () => void;
};

const FiltersModal: FC<Props> = ({ children, reset, apply }) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <StyledButton
        size="medium"
        variant="text"
        startIcon={<IconFilter />}
        endIcon={<IconArrowDown />}
        onClick={handleOpen}
      >
        Фильтр
      </StyledButton>
      <StyledFiltersPopover
        id={v4()}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {children}
        <StyledControls>
          <StyledButtonLink
            size="small"
            variant="text"
            startIcon={<IconArrowUp />}
            onClick={handleClose}
          >
            Свернуть фильтр
          </StyledButtonLink>
          <div>
            <StyledButtonLink size="medium" variant="text" onClick={reset}>
              Сбросить фильтр
            </StyledButtonLink>
            <StyledButtonLink
              size="medium"
              variant="text"
              primary="true"
              onClick={() => {
                apply();
                handleClose();
              }}
            >
              Применить фильтр
            </StyledButtonLink>
          </div>
        </StyledControls>
      </StyledFiltersPopover>
    </>
  );
};

export default FiltersModal;
