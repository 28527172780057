import { styled, css } from '@mui/material/styles';

export const StyledFormGroup = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    gap: 16px;
  `
);

export const StyledLabel = styled('label')(
  () => css`
    flex: none;
    width: 40%;
  `
);

export const StyledControl = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    flex: auto;
  `
);

export const StyledClear = styled('button')<{ value: string }>(
  ({ theme, value }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    opacity: ${value ? 1 : 0};
    visibility: ${value ? 'visible' : 'hidden'};

    svg {
      width: 12px;
      height: 12px;
      color: ${theme.palette.grey['600']};
    }
  `
);
