import { css, styled } from '@mui/material/styles';

export const EditWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `
);

export const StyledFormWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 672px;
    min-width: 510px;
  `
);
