import React from 'react';
import { Button, Stack } from '@mui/material';
import { ROUTES } from '@constants/routes';
import PageContent from '@pages/main-page/content';
import { TableOrganizations } from '@pages/educational-organizations/list/table';
import { useNavigate } from 'react-router-dom';
import { IconPlus, IconImport } from '@components/icons';
import { useGetOrganizations } from '@queries/education-organization';
import { Loading } from '@components/ui/loading';
import FiltersModal from '@components/filters-modal';

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const EduOrganizationList = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetOrganizations();

  const redirectCreateOrganization = () => {
    navigate(ROUTES.createEduOrganization);
  };

  return (
    <PageContent
      breadcrumbs={[
        { title: 'Главная', link: ROUTES.main },
        { title: 'Образовательные организации' },
      ]}
      title="Образовательные организации"
      controlsLeft={
        <Stack direction="row" spacing={1}>
          <Button
            size="medium"
            color="success"
            variant="contained"
            startIcon={<IconPlus />}
            onClick={redirectCreateOrganization}
          >
            Новая запись
          </Button>
          <Button startIcon={<IconImport />} variant="outlined">
            Импорт
          </Button>
        </Stack>
      }
      controlsRight={
        <FiltersModal reset={() => {}} apply={() => {}}>
          filters
        </FiltersModal>
      }
    >
      {isLoading || !data ? <Loading /> : <TableOrganizations data={data} />}
    </PageContent>
  );
};
