import React from 'react';
import { StyleModal } from '@components/ui/modal';
import { Button, Stack, Typography } from '@mui/material';
import Push from '@components/ui/push';

type Props = {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
  action: () => void;
};
export const ModalClose: React.FC<Props> = ({
  open,
  handleClose,
  title,
  description,
  action,
}) => {
  return (
    <StyleModal open={open} handleClose={handleClose}>
      <Typography variant="h2" component="h2">
        {title}
      </Typography>

      <Push size={16} />

      <Typography>{description}</Typography>

      <Push size={16} />

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button onClick={action} sx={{ width: 265 }} fullWidth color="error">
          Выйти без сохранения
        </Button>

        <Button
          onClick={handleClose}
          sx={{ width: 132 }}
          variant="outlined"
          color="primary"
        >
          Отмена
        </Button>
      </Stack>
    </StyleModal>
  );
};
