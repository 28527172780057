import { useState } from 'react';
import ProfilePageHeader from './profile-page-header';
import { PageWrapper } from './styles';

export const ProfilePageTabber = ({
  isEdit,
  renderChildrenByTab,
  activeProfiles,
  setActiveProfiles,
}: {
  isEdit: boolean;
  renderChildrenByTab: (currentTab: string) => React.ReactNode;
  activeProfiles: string[];
  setActiveProfiles: (values: string[]) => void;
}) => {
  const [currentTab, setTab] = useState<string>('general');

  const handleTabChange = (newValue: string) => {
    setTab(newValue);
  };

  return (
    <PageWrapper>
      <ProfilePageHeader
        activeTabs={activeProfiles}
        setActiveTabs={setActiveProfiles}
        isEdit={isEdit}
        currentTab={currentTab}
        handleTabChange={handleTabChange}
      />
      {renderChildrenByTab(currentTab)}
    </PageWrapper>
  );
};
