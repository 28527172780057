import React, { useState } from 'react';
import PageContent from '@pages/main-page/content';
import { ROUTES } from '@constants/routes';
import {
  StyledFlex,
  StyledInputWrapper,
} from '@pages/profiles-page/list/styles';
import { Button } from '@mui/material';
import { IconImport, IconPlusThin } from '@components/icons';
import Push from '@components/ui/push';
import Input from '@components/ui/Input/input';
import { TableProfiles } from '@pages/profiles-page/list/table';
import { useGetAccountList } from '@queries/user';
import { Loading } from '@components/ui/loading';
import { createSearchParams } from '@utils/api';
import { useNavigate } from 'react-router-dom';
import Filters from './filters';

const ProfilesPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isLoading, refetch } = useGetAccountList(
    createSearchParams(searchQuery)
  );

  return (
    <PageContent
      breadcrumbs={[
        { title: 'Главная', link: ROUTES.main },
        { title: 'Учетные записи' },
      ]}
      title="Учетные записи"
      controlsLeft={
        <StyledFlex>
          <Button
            onClick={() => {
              navigate(`${ROUTES.profile}/create`);
            }}
            size="medium"
            color="success"
            startIcon={<IconPlusThin />}
          >
            Новая запись
          </Button>
          <Push size={8} horizontal />
          <Button size="medium" variant="outlined" startIcon={<IconImport />}>
            Импорт
          </Button>
        </StyledFlex>
      }
      controlsRight={
        <StyledFlex>
          <StyledInputWrapper>
            <Input
              value={searchQuery}
              onChange={({ target: { value } }) => {
                setSearchQuery(value);
              }}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  refetch();
                }
              }}
              fullWidth
              variant="outlined"
              placeholder="Поиск"
            />
            <Button
              onClick={() => {
                refetch();
              }}
              size="small"
            >
              Найти
            </Button>
          </StyledInputWrapper>
          <Push size={16} horizontal />
          <Filters apply={() => {}} />
        </StyledFlex>
      }
    >
      {isLoading || !data ? <Loading /> : <TableProfiles data={data.content} />}
    </PageContent>
  );
};

export default ProfilesPage;
