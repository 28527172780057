import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';

import { useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { IconEyeClosedThin, IconEyeOpenThin } from '@components/icons';
import { StyledLink } from '@components/ui/link/styles';
import { useLogin } from '@queries/auth';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants/routes';
import Push from '@components/ui/push';
import { StyledPaper } from './styles';

export const initialLoginData = {
  login: '',
  password: '',
};

const LoginForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const login = useLogin({
    onSuccess: () => {
      navigate(ROUTES.main);
    },
  });

  const onSubmit = (data: typeof initialLoginData) => {
    login.mutate({
      username: data.login,
      password: data.password,
    });
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialLoginData}
      validationSchema={getValidationSchema}
    >
      {({ isValid, errors, touched }: FormikProps<typeof initialLoginData>) => {
        return (
          <Form>
            <StyledPaper elevation={0}>
              <Push size={24} />
              <Typography variant="h2" align="center">
                Sign in to your account
              </Typography>

              <Push size={24} />
              <Field
                name="login"
                as={TextField}
                label="Имя пользователя или E-mail"
                placeholder="exampleemail@example.mail"
                fullWidth
                error={touched.login && !!errors.login}
                helperText={touched.login && errors.login}
              />

              <Push />
              <Field
                name="password"
                as={TextField}
                type={showPassword ? 'text' : 'password'}
                label="Пароль"
                variant="outlined"
                fullWidth
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Показать пароль"
                        onClick={() => setShowPassword((show) => !show)}
                        size="small"
                        edge="end"
                      >
                        {showPassword ? (
                          <IconEyeClosedThin />
                        ) : (
                          <IconEyeOpenThin />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Push size={8} />
              <StyledLink to="." small="true">
                Восстановить
              </StyledLink>

              <Push size={48} />
              <Button
                type="submit"
                disabled={!isValid || login.isLoading}
                size="large"
                fullWidth
              >
                Вход
              </Button>

              <Push size={24} />
              <Typography align="center" component="div">
                Или войдите через{' '}
                <StyledLink to=".">ESIA (CryptoPRO)</StyledLink>
              </Typography>

              <Push size={24} />
              <Typography align="center" component="div">
                <StyledLink to=".">Не удается войти?</StyledLink>
              </Typography>
              <Push size={24} />
            </StyledPaper>

            <Push size={40} />
            <Typography align="center" component="div">
              <StyledLink to=".">Зарегистрироваться</StyledLink>
            </Typography>
            <Push size={40} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;

const getValidationSchema = () =>
  objectYup().shape({
    login: stringYup()
      .nullable()
      .required('Введите имя пользователя или e-mail'),
    password: stringYup().nullable().min(6, '').required('Введите пароль'),
  });
