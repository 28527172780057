import { useQueryClient } from '@tanstack/react-query';
import { dictionaryApi } from '@api/dictionary';
import { useEffect, useState } from 'react';
import { Nullable } from '@declarations/index';
import {
  SelectOption,
  SelectValueType,
} from '@components/ui/select/multi-select';

type Dictionary = Nullable<string[]>;

export enum DictionaryEnum {
  StructuralRoleType = 'structuralroletype',
  PupilAttendanceForm = 'pupilattendanceform',
  OrganizationType = 'organizationtype',
  OrganizationIdentifierType = 'organizationidentifiertype',
  JuvenileStatus = 'juvenilestatus',
  EduOrganizationGroupType = 'eduorganizationgrouptype',
  ContactType = 'contacttype',
  ConsentStatus = 'consentstatus',
  AddressType = 'addresstype',
  AccountStatus = 'accountstatus',
  AccountIdentifierType = 'accountidentifiertype',
}

type Response = {
  [DictionaryEnum.StructuralRoleType]?: Dictionary;
  [DictionaryEnum.PupilAttendanceForm]?: Dictionary;
  [DictionaryEnum.OrganizationType]?: Dictionary;
  [DictionaryEnum.OrganizationIdentifierType]?: Dictionary;
  [DictionaryEnum.JuvenileStatus]?: Dictionary;
  [DictionaryEnum.EduOrganizationGroupType]?: Dictionary;
  [DictionaryEnum.ContactType]?: Dictionary;
  [DictionaryEnum.ConsentStatus]?: Dictionary;
  [DictionaryEnum.AddressType]?: Dictionary;
  [DictionaryEnum.AccountStatus]?: Dictionary;
  [DictionaryEnum.AccountIdentifierType]?: Dictionary;
};

// пример использования
// const data = useGetDictionary([DictionaryEnum.OrganizationIdentifierType]);
export const useGetDictionary = (dictionary: string[]): any => {
  const [data, setData] = useState<Response>({});
  const useDictionary: Record<string, boolean> = {};

  const queryClient = useQueryClient();

  dictionary.forEach((type) => {
    useDictionary[type] = true;
  });

  const fetchData = async (type: string) => {
    try {
      // @ts-ignore
      return await queryClient.fetchQuery([type], dictionaryApi[type]);
    } catch (error) {
      console.error(`Ошибка при получении данных ${type}:`, error);
      return null;
    }
  };

  const fetchDataForActiveTypes = async () => {
    const result: Response = {};

    await Promise.all(
      dictionary.map(async (type) => {
        if (useDictionary[type]) {
          const response = await fetchData(type);
          if (response) {
            // @ts-ignore
            result[type] = response.reduce(
              (acc: SelectOption[], item: SelectValueType) => {
                if (item) {
                  acc.push({
                    label: item as SelectOption['label'],
                    value: item,
                  });
                }
                return acc;
              },
              []
            );
          }
        }
      })
    );

    setData(result);
    return result;
  };

  useEffect(() => {
    fetchDataForActiveTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};
