import { styled, css } from '@mui/material/styles';
import {
  closedDrawerWidth,
  openedDrawerWidth,
} from '@components/navigation-bar/navigation-bar';
import { Box } from '@mui/material';

export const StyledLayout = styled(Box)<{ opendrawer: number }>(
  ({ theme, opendrawer }) => css`
    min-height: 100vh;
    padding-left: ${opendrawer ? openedDrawerWidth : closedDrawerWidth}px;
    transition: padding-left ${theme.transitions.duration.standard}ms !important;
    background-color: ${theme.palette.common.white};
  `
);

export const StyledContent = styled('div')(
  () => css`
    padding: 32px;
  `
);
