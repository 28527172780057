import { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Header from '@components/header';
import { StyledBadge } from '@components/navigation-bar/styles';
import MultiSelectComponent, {
  SelectValueType,
  SelectOption,
} from '@components/ui/select/multi-select';
import { ROUTES } from '@constants/routes';

type Props = {
  activeTabs: string[];
  currentTab: string;
  isEdit?: boolean;
  handleTabChange: (newValue: string) => void;
  setActiveTabs: (values: string[]) => void;
};

const profileOptions: SelectOption[] = [
  { value: 'psychologist', label: 'Педагог-психиолог' },
  { value: 'next-of-kin', label: 'Законный представитель' },
  { value: 'admin', label: 'Администратор' },
  { value: 'pupil', label: 'Несовершеннолетний' },
  { value: 'controller', label: 'Персонал платформы' },
];

const ProfilePageHeader: FC<Props> = ({
  activeTabs,
  setActiveTabs,
  currentTab,
  handleTabChange,
  isEdit,
}) => {
  const onToggleChange = (newValue: string) => {
    handleTabChange(newValue);
  };
  const handleCheckedChange = (value: SelectValueType[]) => {
    setActiveTabs(value as string[]);
  };
  return (
    <Header
      breadcrumbs={[
        { title: 'Главная', link: ROUTES.main },
        { title: 'Учетные записи', link: ROUTES.allProfiles },
      ]}
      title={
        isEdit ? 'Редактирование учётной записи' : 'Создание учётной записи'
      }
      controlsLeft={
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={currentTab}
          onChange={(ev, newValue) => {
            onToggleChange(newValue);
          }}
        >
          <ToggleButton value="general">
            Учётная запись
            <StyledBadge badgeContent={2} color="primary" />
          </ToggleButton>
          {activeTabs.map((item) => {
            const option = profileOptions.find(
              (option) => option.value === item
            );

            return option ? (
              <ToggleButton value={option.value}>{option.label}</ToggleButton>
            ) : null;
          })}
        </ToggleButtonGroup>
      }
      controlsRight={
        <MultiSelectComponent
          value={activeTabs}
          options={profileOptions}
          handleChange={handleCheckedChange}
          asButtonLabel="Редактировать профили"
          menuHeader="Снятие/Постановка профиля"
        />
      }
    />
  );
};

export default ProfilePageHeader;
