import { OutlinedTextFieldProps, TextField, styled } from '@mui/material';

type InputSizes = 'small' | 'medium';

type InputProps = OutlinedTextFieldProps & {
  size?: InputSizes;
};

const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'size',
})<InputProps>(({ size, theme }) => ({
  borderColor: theme.palette.grey[500],
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[500],
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[700],
    borderWidth: '1px',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    height: size === 'small' ? '36px' : '44px',
    padding: size === 'small' ? '8px 16px' : '10px 16px',
    fontSize: size === 'small' ? '14px' : '16px',
  },
  '.MuiInputBase-root': {
    borderRadius: 12,
  },
}));

const Input = (props: InputProps) => {
  return <StyledInput {...props} />;
};
export default Input;
