import { Admin } from '@declarations/profiles/admin';
import { useEditAdminMutation } from '@queries/profiles';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { GeneralAdminEdit } from './general';
import { StyledFormWrapper } from '../styles';
import { KeyedAdminRole, RolesEdit } from './roles';

type Props = {
  data?: Admin;
  isLoading?: boolean;
  needToSubmit?: boolean;
  setNeedToSubmit: (needToSubmit: boolean) => void;
};

function getInitialState(): Admin {
  return {
    id: null,
    authAccountId: null,
    status: 'CONFIRMED',
    position: '',
    roles: [],
    organizationDTO: { alias: '' },
  };
}

export const AdminForm: FC<Props> = ({
  data,
  needToSubmit,
  setNeedToSubmit,
}) => {
  const editMutation = useEditAdminMutation();
  const formik = useFormik({
    initialValues: data || getInitialState(),
    onSubmit: (values) => {
      const state = { ...values };
      state.roles = state.roles.map((item) => {
        const { key, ...rest } = item as KeyedAdminRole;
        return rest;
      });

      editMutation.mutate(state);
      setNeedToSubmit(false);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (needToSubmit) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToSubmit]);
  return (
    <FormikProvider value={formik}>
      <Form>
        <StyledFormWrapper>
          <GeneralAdminEdit />
          <RolesEdit name="roles" />
        </StyledFormWrapper>
      </Form>
    </FormikProvider>
  );
};
