import React, { useState } from 'react';

import { StyledFormWrapper } from '@pages/profile-page/forms/styles';
import { Form, Formik, FormikProps } from 'formik';
import { GeneralEdit } from '@pages/educational-organizations/organization/components/edit/general';
import { ContactEdit } from '@pages/educational-organizations/organization/components/edit/contact';
import { AddressesEdit } from '@pages/educational-organizations/organization/components/edit/addresses';
import { EducationOrganization } from '@declarations/education-organization';
import {
  array as arrayYup,
  object as objectYup,
  string as stringYup,
} from 'yup';
import { ROUTES } from '@constants/routes';
import { useNavigate } from 'react-router-dom';
import { ControlPanel } from '@components/control-panel';
import { useCreateOrganization } from '@queries/education-organization';
import { ModalClose } from '@components/modal-close';
import { IdentifiersEdit } from './components/edit/identifiers';

const initialValues: EducationOrganization = {
  id: 0,
  name: '',
  alias: '',
  executiveName: '',
  executivePosition: '',
  type: '',
  partOf: {
    id: 1,
    name: '',
  },
  active: false,
  effectiveDate: new Date().toString(),
  organizationIdentifiers: [],
  organizationAddresses: [],
  contactPoints: [],
};

export const CreateOrganization = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const createOrganization = useCreateOrganization({
    onSuccess: () => {
      back();
    },
  });

  const submitForm = (values: EducationOrganization) => {
    createOrganization.mutate(values);
  };

  const back = () => navigate(ROUTES.eduOrganizations);

  return (
    <>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getValidationSchema}
        onSubmit={submitForm}
      >
        {({ isValid, values }: FormikProps<EducationOrganization>) => {
          return (
            <Form>
              <StyledFormWrapper>
                <GeneralEdit />
                <IdentifiersEdit name="organizationIdentifiers" />
                <ContactEdit name="contactPoints" />
                <AddressesEdit name="organizationAddresses" />
              </StyledFormWrapper>

              <ControlPanel
                cancelFn={() => setOpenModal(true)}
                disabledAction={!isValid}
                actionName="Создать организацию"
                actionFn={() => submitForm(values)}
              />
            </Form>
          );
        }}
      </Formik>

      <ModalClose
        open={openModal}
        action={() => navigate(ROUTES.eduOrganizations)}
        handleClose={() => setOpenModal(false)}
        title="Возврат к списку организаций"
        description="Все внесенные данные удалятся без сохранения, продолжить?"
      />
    </>
  );
};

const REQUIRED_MESSAGE = 'Это обязательное поле';
export const getValidationSchema = () =>
  objectYup().shape({
    name: stringYup().nullable().required(REQUIRED_MESSAGE),
    type: stringYup().nullable().required(REQUIRED_MESSAGE),
    organizationIdentifiers: arrayYup()
      .min(1, 'Добавьте хотя бы один идентификатор')
      .of(
        objectYup().shape({
          type: stringYup().required(REQUIRED_MESSAGE),
          identifier: stringYup().required(REQUIRED_MESSAGE),
        })
      ),
    contactPoints: arrayYup()
      .min(1, 'Добавьте хотя бы один контакт')
      .of(
        objectYup().shape({
          type: stringYup().required(REQUIRED_MESSAGE),
          contact: stringYup().required(REQUIRED_MESSAGE),
        })
      ),
    organizationAddresses: arrayYup()
      .min(1, 'Добавьте хотя бы один адрес')
      .of(
        objectYup().shape({
          type: stringYup().required(REQUIRED_MESSAGE),
          country: stringYup().required(REQUIRED_MESSAGE),
        })
      ),
  });
