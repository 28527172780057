import { FC } from 'react';
import { useGetPsychologist } from '@queries/profiles';
import { PsychologistForm } from './psychologist-form';

type Props = {
  psychologistId?: number;
  needToSubmit: boolean;
  setNeedToSubmit: (needToSubmit: boolean) => void;
};

export const PsychologistProfile: FC<Props> = ({
  psychologistId,
  needToSubmit,
  setNeedToSubmit,
}) => {
  const { data: psychologistFromApi, status } = useGetPsychologist({
    psychologistId,
  });

  return psychologistId ? (
    <PsychologistForm
      data={psychologistFromApi}
      isLoading={status === 'loading'}
      needToSubmit={needToSubmit}
      setNeedToSubmit={setNeedToSubmit}
    />
  ) : (
    <PsychologistForm
      needToSubmit={needToSubmit}
      setNeedToSubmit={setNeedToSubmit}
    />
  );
};
